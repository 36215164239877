import React, { Component } from 'react';
import 'react-tabs/style/react-tabs.css';
import Header from '../layout/Header';
import Detailbox from './Detail_box';

export const Cloud_services = (props) => {
    return (
        <div className="site-main">
            <Header />
            {/* page-title */}
            <div className="ttm-page-title-row">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                    <h1 className="title">Cloud Services</h1>
                                </div>{/* /.page-title-captions */}                         
                            </div>
                        </div>{/* /.col-md-12 */}
                    </div>{/* /.row */}
                </div>{/* /.container */}
            </div>
            <section className="ttm-row project-single-section clearfix">
                <div className="container-xl">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-6 col-xl-8">
                            {/* ttm_single_image-wrapper */}
                            <div className="ttm_single_image-wrapper mb-35">
                                <img className="img-fluid" src="images/Hire-Candidate-1200_800/hire_can-4.jpg" title="single-img-twenty" alt="single-img-twenty" />
                            </div>{/* ttm_single_image-wrapper end */}
                        </div>
                        <div className="col-lg-6 col-xl-4">
                            <Detailbox />
                        </div>
                    </div>
                    {/* row end*/}
                    {/* row */}
                    <div className="row">
                        <div className="col-12">
                            <h4>Innovative Interface of cloud services</h4>
                            <p>A voice-controlled platform could be an innovative interface for cloud services, enabling users to manage their resources and services with natural language commands. The platform would use AI and natural language processing to interpret commands, allowing users to launch new instances, scale resources, and perform backups and recovery tasks. Real-time monitoring and analysis could provide insights and recommendations to optimize performance and reduce costs. Integration with other tools would provide a seamless experience, revolutionizing the way users interact with their cloud resources and making management faster and more accessible.</p>
                            <h4>You may also know how it uses in IT field</h4>
                            <p>Cloud services are a crucial part of the IT industry, providing various benefits to businesses and organizations. They offer cost-effective solutions for data storage, software development, and infrastructure management.</p>
                        </div>
                        <div className="col-md-4">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style3 left-icon icon-align-top">
                                <div className="featured-icon">{/* featured-icon */}
                                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon flaticon-algorithm" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">{/* featured title */}
                                        <h5>Expert Team</h5>
                                    </div>
                                    <div className="featured-desc">{/* featured desc */}
                                        <p>Our expert team consists of highly skilled professionals with a passion for delivering exceptional results.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style3 left-icon icon-align-top">
                                <div className="featured-icon">{/* featured-icon */}
                                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon flaticon-padlock" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">{/* featured title */}
                                        <h5>Free Consultation</h5>
                                    </div>
                                    <div className="featured-desc">{/* featured desc */}
                                        <p>Our free consultation service offers a valuable opportunity for individuals to receive expert advice and guidance without any obligation.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style3 left-icon icon-align-top">
                                <div className="featured-icon">{/* featured-icon */}
                                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon flaticon-process-1" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">{/* featured title */}
                                        <h5>Lowest Cost</h5>
                                    </div>
                                    <div className="featured-desc">{/* featured desc */}
                                        <p>Providing high-quality service at the lowest possible cost.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <h4 className="mt-35">Service Result &amp; Benefits of Service</h4>
                            <p className="mb-0">Cloud services also provide businesses with access to virtualized infrastructure and software applications on a subscription basis. Overall, cloud services have revolutionized the way businesses operate by providing flexibility, scalability, and cost savings.</p>
                        </div>
                        <div className="col-12">
                            {/* ttm_single_image-wrapper */}
                            <div className="ttm_single_image-wrapper mt-35">
                                <img className="img-fluid" src="images/Project 1171_548/project-1.jpg" title="single-img-twentyone" alt="single-img-twentyone" />
                            </div>{/* ttm_single_image-wrapper end */}
                        </div>
                        {/* separator */}
                        <div className="col-12">
                            <div className="separator">
                                <div className="sep-line mt-35 mb-35" />
                            </div>
                        </div>

                    </div>{/* row end*/}
                    {/* row */}
                    <div className="row ttm-pf-single-related-wrapper ">
                        <div className="col-12">
                            <h4 className="mb-30">Related Projects</h4>
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/Service/Portfolio/port 1.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="category">
                                        <p>Business , Campaign</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a href={process.env.PUBLIC_URL + '/graphic_design'}>Graphic Design</a></h5>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/Service/Portfolio/port 3.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="category">
                                        <p>Campaign , Technology</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a href={process.env.PUBLIC_URL + '/cloud_services'}>Cloud Services</a></h5>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/Service/Portfolio/port 4.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="category">
                                        <p>Corporate , Workshop</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a href={process.env.PUBLIC_URL + '/web_development'}>Web Development</a></h5>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox */}
                        </div>
                    </div>{/* row end*/}
                </div>
            </section>
        </div>
    )
}



export default Cloud_services;