import React from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import CountUp from 'react-countup';
import Sidebar from '../../services/Sidebar';
import Slider from 'react-slick';
import Header from '../../layout/Header';

export const WordPress = (props) => {
    var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,

        responsive: [{

            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {

            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {

            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };

    return (
        <div className="site-main">
            <Header />
            {/* page-title */}
            <div className="ttm-page-title-row">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                    <h1 className="title">Hire Wordpress Developers</h1>
                                </div>{/* /.page-title-captions */}
                                <div className="breadcrumb-wrapper">
                                    <p>We have a team of expert WordPress developers who can design, develop, and customize your WordPress website to ensure it is visually stunning, user-friendly, and optimized for search engines.</p>
                                </div>
                            </div>
                        </div>{/* /.col-md-12 */}
                    </div>{/* /.row */}
                </div>{/* /.container */}
            </div>
            <section className="ttm-row our-client-section res-991-p-0 ttm-bgcolor-skincolor clearfix">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-12 text-center">
                        <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={5}>
                                <div className="ttm-box-col-wrapper">
                                    <div className="client-box ttm-box-view-boxed-logo">
                                        <div className="client">
                                            <div className="ttm-client-logo-tooltip" data-tooltip="Adobe">
                                                <img className="img-fluid" src="images/Compeny-logo/Company=Adobe.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-box-col-wrapper">
                                    <div className="client-box ttm-box-view-boxed-logo">
                                        <div className="client">
                                            <div className="ttm-client-logo-tooltip" data-tooltip="Fivetran">
                                                <img className="img-fluid" src="images/Compeny-logo/Company=Fivetran.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-box-col-wrapper">
                                    <div className="client-box ttm-box-view-boxed-logo">
                                        <div className="client">
                                            <div className="ttm-client-logo-tooltip" data-tooltip="Airbnb">
                                                <img className="img-fluid" src="images/Compeny-logo/Company=Airbnb.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-box-col-wrapper">
                                    <div className="client-box ttm-box-view-boxed-logo">
                                        <div className="client">
                                            <div className="ttm-client-logo-tooltip" data-tooltip="Airbnb">
                                                <img className="img-fluid" src="images/Compeny-logo/Company=Opendoor.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-box-col-wrapper">
                                    <div className="client-box ttm-box-view-boxed-logo">
                                        <div className="client">
                                            <div className="ttm-client-logo-tooltip" data-tooltip="Airtable">
                                                <img className="img-fluid" src="images/Compeny-logo/Company=Airtable.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
            <section className="ttm-row process-section clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              {/* section title */}
              <div className="section-title text-center with-desc clearfix">
                <div className="title-header">
                  <h5>how it work</h5>
                  <h2 className="title">Check Out Our Work <span>Process</span></h2>
                </div>
              </div>{/* section title end */}
            </div>
          </div>
          {/* row */}
          <div className="row">
            <div className="col-lg-12">
              <div className="ttm-processbox-wrapper">
                <div className="ttm-processbox text-center">
                  <div className="ttm-box-image">
                    <img className="img-fluid" alt="step-one" title="step-one" src="images/process/prosses-4.png" />
                    <div className="process-num">
                      <span className="number">1</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title"><h5>Choose a Service</h5></div>
                    <div className="ttm-box-description">You need to choose a service regarding project for accomplishment.</div>
                  </div>
                </div>
                <div className="ttm-processbox text-center">
                  <div className="ttm-box-image">
                    <img className="img-fluid" alt="step-two" title="step-two" src="images/process/prosses-1.png" />
                    <div className="process-num">
                      <span className="number">2</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title"><h5>Request a Meeting</h5></div>
                    <div className="ttm-box-description">After choosing service the next step is to request a meeting with managers.</div>
                  </div>
                </div>
                <div className="ttm-processbox text-center">
                  <div className="ttm-box-image">
                    <img className="img-fluid" alt="step-three" title="step-three" src="images/process/prosses-2.png" />
                    <div className="process-num">
                      <span className="number">3</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title"><h5>Receive Custom Plan</h5></div>
                    <div className="ttm-box-description">We have optimised plan according to your budget for each service.</div>
                  </div>
                </div>
                <div className="ttm-processbox text-center res-991-mb-0">
                  <div className="ttm-box-image">
                    <img className="img-fluid" alt="step-four" title="step-four" src="images/process/prosses-3.png" />
                    <div className="process-num">
                      <span className="number">4</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title"><h5>Let’s Make it Happen</h5></div>
                    <div className="ttm-box-description">It’s time to us to deliver your project on time with satisfaction.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>{/* row end */}
        </div>
      </section>
            {/* page-title end*/}
            <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                <div className="container-xl">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-9 content-area order-lg-2">
                            {/* ttm-service-single-content-are */}
                            {/* ttm-service-single-content-are */}
                            <div className="ttm-service-single-content-area">
                                <div className="ttm_single_image-wrapper mb-35">
                                    <img className="img-fluid" src="images/Hire-Candidate-1200_800/hire_can-5.jpg" alt="single-img-twelve" />
                                </div>
                                <div className="ttm-service-description">
                                    <h3>Hire Wordpress Developers from Todomist</h3>
                                    <p>WordPress is a popular content management system (CMS) that is widely used for building websites and blogs.</p>
                                    <p>A skilled WordPress developer can help you create custom themes and plugins, as well as customize and maintain your WordPress website.</p>
                                    <div className="row no-gutters box-shadow mb-35">
                                        <div className="col-md-4 col-sm-4">
                                            {/*ttm-fid*/}
                                            <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                                                <div className="ttm-fid-left">
                                                    <div className="ttm-fid-icon-wrapper">
                                                        <i className="flaticon flaticon-global-1 ttm-textcolor-skincolor" />
                                                    </div>
                                                </div>
                                                <div className="ttm-fid-contents text-left">
                                                    <h4 className="ttm-fid-inner">
                                                        <CountUp start={0} end={9} duration={30} /> +
                                                    </h4>
                                                    <h3 className="ttm-fid-title">Years Experience</h3>
                                                </div>
                                            </div>{/* ttm-fid end*/}
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            {/*ttm-fid*/}
                                            <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                                                <div className="ttm-fid-left">
                                                    <div className="ttm-fid-icon-wrapper">
                                                        <i className="flaticon flaticon-padlock ttm-textcolor-skincolor" />
                                                    </div>
                                                </div>
                                                <div className="ttm-fid-contents text-left">
                                                    <h4 className="ttm-fid-inner">
                                                        <CountUp start={0} end={590} duration={30} /> +
                                                    </h4>
                                                    <h3 className="ttm-fid-title">Happy Clients</h3>
                                                </div>
                                            </div>{/* ttm-fid end*/}
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            {/*ttm-fid*/}
                                            <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                                                <div className="ttm-fid-left">
                                                    <div className="ttm-fid-icon-wrapper">
                                                        <i className="flaticon flaticon-developer ttm-textcolor-skincolor" />
                                                    </div>
                                                </div>
                                                <div className="ttm-fid-contents text-left">
                                                    <h4 className="ttm-fid-inner">
                                                        <CountUp start={0} end={95} duration={30} /> +
                                                    </h4>
                                                    <h3 className="ttm-fid-title">New Candidate</h3>
                                                </div>
                                            </div>{/* ttm-fid end*/}
                                        </div>
                                    </div>
                                    <h3>Why Choose Us?</h3>
                                    <div className="row mt-35">
                                        <div className="col-lg-12">
                                            <div className="row m-0 box-shadow spacing-11">
                                                <div className="col-md-6 col-lg-12 col-xl-6">
                                                    <div className="ttm_single_image-wrapper">
                                                        <img className="img-fluid" src="images/Hire-Candidate-1200_800/385_353/choose-3.png" alt="single-img-thirteen" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-12 col-xl-6">
                                                     <ul className="ttm-list ttm-list-style-icon">
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Skip sourcing</span>  <p>Eradicate the long and tedious process of sourcing candidates to find the right fit.</p></li>                                                      
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">No pre or post-hiring responsibilities</span> <p>From helping you build a great team to managing them (HR and Payrolls) - we will take care of everything.</p></li>                                                       
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Save on Salary</span> <p>Todomist saves you up to 40% on salary over local talent without compromising on quality</p></li>                                                      
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Beat Talent Crunch </span>   <p> Access a network of experienced candidates and hire the top Angular Developers for app and web development.</p></li>                                                    
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">No Obligation Interview</span>   <p> Interviewing a candidate does not obligate you to hire them. We recommend other profiles if you're not satisfied. No questions asked.</p></li>                                                   
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="mt-35">Frequently Asked Questions</h3>
                                     {/* acadion */}
                                    <Accordion className="accordion style2">
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                What skills should a WordPress developer have?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="mb-0">
                                                A good WordPress developer should have a strong understanding of PHP, HTML, CSS, JavaScript, and SQL. They should also be familiar with the WordPress platform and its themes, plugins, and APIs.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                What kind of websites can a WordPress developer create?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="mb-0">
                                                WordPress developers can create a wide range of websites, from simple blogs and portfolio sites to complex e-commerce platforms and enterprise-level web applications.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                How do I hire a skilled WordPress developer?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="mb-0">
                                                To hire a skilled WordPress developer, you can search for candidates on freelance platforms, job boards, or through referrals from your professional network. It is important to review their portfolio, experience, and client feedback before making a hiring decision.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                How much does it cost to hire a WordPress developer?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="mb-0">
                                                The cost of hiring a WordPress developer can vary depending on their experience, skill level, location, and project requirements. Hourly rates can range from $20 to $150 per hour, and project-based fees can range from a few hundred dollars to tens of thousands of dollars.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                What ongoing support and maintenance services can a WordPress developer provide?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="mb-0">
                                                A WordPress developer can provide ongoing support and maintenance services, such as security updates, plugin updates, website backups, and performance optimization. They can also help with troubleshooting issues and fixing bugs on your WordPress site.
                                                   </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </div>
                            {/* ttm-service-single-content-are end */}
                        </div>
                        <div className="col-lg-3 widget-area">
                            <Sidebar />
                        </div>
                    </div>{/* row end */}
                </div>
            </div>
        </div>
    )
}



export default WordPress;