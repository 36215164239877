import React from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import CountUp from 'react-countup';
import Sidebar from '../../services/Sidebar';
import Slider from 'react-slick';
import Header from '../../layout/Header';

export const PHP = (props) => {
    var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,

        responsive: [{

            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {

            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {

            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };

    return (
        <div className="site-main">
            <Header />
            {/* page-title */}
            <div className="ttm-page-title-row">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                    <h1 className="title">Hire PHP Developers</h1>
                                </div>{/* /.page-title-captions */}
                                <div className="breadcrumb-wrapper">
                                    <p>Our team includes highly skilled PHP developers who can provide expertise in developing custom web applications, integrating third-party APIs, and optimizing code to ensure optimal performance, scalability, and security for your business.</p>
                                </div>
                            </div>
                        </div>{/* /.col-md-12 */}
                    </div>{/* /.row */}
                </div>{/* /.container */}
            </div>
            <section className="ttm-row our-client-section res-991-p-0 ttm-bgcolor-skincolor clearfix">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-12 text-center">
                        <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={5}>
                                <div className="ttm-box-col-wrapper">
                                    <div className="client-box ttm-box-view-boxed-logo">
                                        <div className="client">
                                            <div className="ttm-client-logo-tooltip" data-tooltip="Adobe">
                                                <img className="img-fluid" src="images/Compeny-logo/Company=Adobe.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-box-col-wrapper">
                                    <div className="client-box ttm-box-view-boxed-logo">
                                        <div className="client">
                                            <div className="ttm-client-logo-tooltip" data-tooltip="Fivetran">
                                                <img className="img-fluid" src="images/Compeny-logo/Company=Fivetran.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-box-col-wrapper">
                                    <div className="client-box ttm-box-view-boxed-logo">
                                        <div className="client">
                                            <div className="ttm-client-logo-tooltip" data-tooltip="Airbnb">
                                                <img className="img-fluid" src="images/Compeny-logo/Company=Airbnb.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-box-col-wrapper">
                                    <div className="client-box ttm-box-view-boxed-logo">
                                        <div className="client">
                                            <div className="ttm-client-logo-tooltip" data-tooltip="Airbnb">
                                                <img className="img-fluid" src="images/Compeny-logo/Company=Opendoor.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-box-col-wrapper">
                                    <div className="client-box ttm-box-view-boxed-logo">
                                        <div className="client">
                                            <div className="ttm-client-logo-tooltip" data-tooltip="Airtable">
                                                <img className="img-fluid" src="images/Compeny-logo/Company=Airtable.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>

                        </div>
                    </div>
                </div>
            </section>
            <section className="ttm-row process-section clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              {/* section title */}
              <div className="section-title text-center with-desc clearfix">
                <div className="title-header">
                  <h5>how it work</h5>
                  <h2 className="title">Check Out Our Work <span>Process</span></h2>
                </div>
              </div>{/* section title end */}
            </div>
          </div>
          {/* row */}
          <div className="row">
            <div className="col-lg-12">
              <div className="ttm-processbox-wrapper">
                <div className="ttm-processbox text-center">
                  <div className="ttm-box-image">
                    <img className="img-fluid" alt="step-one" title="step-one" src="images/process/prosses-4.png" />
                    <div className="process-num">
                      <span className="number">1</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title"><h5>Choose a Service</h5></div>
                    <div className="ttm-box-description">You need to choose a service regarding project for accomplishment.</div>
                  </div>
                </div>
                <div className="ttm-processbox text-center">
                  <div className="ttm-box-image">
                    <img className="img-fluid" alt="step-two" title="step-two" src="images/process/prosses-1.png" />
                    <div className="process-num">
                      <span className="number">2</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title"><h5>Request a Meeting</h5></div>
                    <div className="ttm-box-description">After choosing service the next step is to request a meeting with managers.</div>
                  </div>
                </div>
                <div className="ttm-processbox text-center">
                  <div className="ttm-box-image">
                    <img className="img-fluid" alt="step-three" title="step-three" src="images/process/prosses-2.png" />
                    <div className="process-num">
                      <span className="number">3</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title"><h5>Receive Custom Plan</h5></div>
                    <div className="ttm-box-description">We have optimised plan according to your budget for each service.</div>
                  </div>
                </div>
                <div className="ttm-processbox text-center res-991-mb-0">
                  <div className="ttm-box-image">
                    <img className="img-fluid" alt="step-four" title="step-four" src="images/process/prosses-3.png" />
                    <div className="process-num">
                      <span className="number">4</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title"><h5>Let’s Make it Happen</h5></div>
                    <div className="ttm-box-description">It’s time to us to deliver your project on time with satisfaction.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>{/* row end */}
        </div>
      </section>
            {/* page-title end*/}
            <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                <div className="container-xl">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-9 content-area order-lg-2">
                            {/* ttm-service-single-content-are */}
                            {/* ttm-service-single-content-are */}
                            <div className="ttm-service-single-content-area">
                                <div className="ttm_single_image-wrapper mb-35">
                                    <img className="img-fluid" src="images/Hire-Candidate-1200_800/hire_can-7.jpg" alt="single-img-twelve" />
                                </div>
                                <div className="ttm-service-description">
                                    <h3>Hire PHP Developers from Todomist</h3>
                                    <p>PHP is a widely used server-side scripting language that can be used for building dynamic and interactive web applications.</p>
                                    <p> A skilled PHP developer can help you create custom web applications that are tailored to your specific needs.</p>
                                    <div className="row no-gutters box-shadow mb-35">
                                        <div className="col-md-4 col-sm-4">
                                            {/*ttm-fid*/}
                                            <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                                                <div className="ttm-fid-left">
                                                    <div className="ttm-fid-icon-wrapper">
                                                        <i className="flaticon flaticon-global-1 ttm-textcolor-skincolor" />
                                                    </div>
                                                </div>
                                                <div className="ttm-fid-contents text-left">
                                                    <h4 className="ttm-fid-inner">
                                                        <CountUp start={0} end={9} duration={30} /> +
                                                    </h4>
                                                    <h3 className="ttm-fid-title">Years Experience</h3>
                                                </div>
                                            </div>{/* ttm-fid end*/}
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            {/*ttm-fid*/}
                                            <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                                                <div className="ttm-fid-left">
                                                    <div className="ttm-fid-icon-wrapper">
                                                        <i className="flaticon flaticon-padlock ttm-textcolor-skincolor" />
                                                    </div>
                                                </div>
                                                <div className="ttm-fid-contents text-left">
                                                    <h4 className="ttm-fid-inner">
                                                        <CountUp start={0} end={490} duration={30} /> +
                                                    </h4>
                                                    <h3 className="ttm-fid-title">Happy Clients</h3>
                                                </div>
                                            </div>{/* ttm-fid end*/}
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            {/*ttm-fid*/}
                                            <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                                                <div className="ttm-fid-left">
                                                    <div className="ttm-fid-icon-wrapper">
                                                        <i className="flaticon flaticon-developer ttm-textcolor-skincolor" />
                                                    </div>
                                                </div>
                                                <div className="ttm-fid-contents text-left">
                                                    <h4 className="ttm-fid-inner">
                                                        <CountUp start={0} end={92} duration={30} /> +
                                                    </h4>
                                                    <h3 className="ttm-fid-title">New Candidate</h3>
                                                </div>
                                            </div>{/* ttm-fid end*/}
                                        </div>
                                    </div>
                                    <h3>Why Choose Us?</h3>
                                    <div className="row mt-35">
                                        <div className="col-lg-12">
                                            <div className="row m-0 box-shadow spacing-11">
                                                <div className="col-md-6 col-lg-12 col-xl-6">
                                                    <div className="ttm_single_image-wrapper">
                                                        <img className="img-fluid" src="images/Hire-Candidate-1200_800/385_353/choose-1.png" alt="single-img-thirteen" />
                                                    </div>
                                                </div>
                                                <div className="col-md-6 col-lg-12 col-xl-6">
                                                     <ul className="ttm-list ttm-list-style-icon">
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Skip sourcing</span>  <p>Eradicate the long and tedious process of sourcing candidates to find the right fit.</p></li>                                                      
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">No pre or post-hiring responsibilities</span> <p>From helping you build a great team to managing them (HR and Payrolls) - we will take care of everything.</p></li>                                                       
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Save on Salary</span> <p>Todomist saves you up to 40% on salary over local talent without compromising on quality</p></li>                                                      
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Beat Talent Crunch </span>   <p> Access a network of experienced candidates and hire the top Angular Developers for app and web development.</p></li>                                                    
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">No Obligation Interview</span>   <p> Interviewing a candidate does not obligate you to hire them. We recommend other profiles if you're not satisfied. No questions asked.</p></li>                                                   
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="mt-35">Frequently Asked Questions</h3>
                                    {/* acadion  */}
                                    <Accordion className="accordion style2">
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                What is a PHP developer?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="mb-0">
                                                A PHP developer is a software developer who specializes in writing server-side web applications using the PHP programming language. They are responsible for designing, developing, and maintaining web applications that run on PHP frameworks and integrating them with databases and other web technologies. PHP developers typically have expertise in PHP, SQL, HTML, CSS, and JavaScript and work on a variety of projects such as e-commerce websites, content management systems, and web applications.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                How to hire a top PHP developer?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="mb-0">
                                                 To hire a top PHP developer, you can follow these steps:
                                                 1. Define your project requirements and scope<br/>
                                                 2. Search for top PHP developers on freelance websites, job boards, or through referrals<br/>
                                                 3. Evaluate the candidates based on their skills, experience, and portfolio<br/>
                                                 4. Conduct an interview to assess their communication and problem-solving abilities<br/>
                                                 5. Check references and past client reviews<br/>
                                                 6. Negotiate the terms of the project, such as payment, timeline, and communication channels<br/>
                                                 7. Sign a contract or agreement outlining the project details and expectations.<br/>
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                Why hire a dedicated PHP developer?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="mb-0">
                                                Dedicated PHP developers can bring several benefits to your project, including expertise, cost-effectiveness, faster development, flexibility, ongoing support, and maintenance.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                How much does it cost to hire a PHP developer?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="mb-0">
                                                The cost of hiring a PHP developer varies depending on factors such as their level of experience, location, and the scope of the project. Generally, monthly rates for PHP developers range from $2200-2500 per month.
                                                </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </div>
                            {/* ttm-service-single-content-are end */}
                        </div>
                        <div className="col-lg-3 widget-area">
                            <Sidebar />
                        </div>
                    </div>{/* row end */}
                </div>
            </div>
        </div>
    )
}



export default PHP;