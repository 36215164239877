import React, { Component } from 'react';
import {
  MDBNavbar, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBCollapse, MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem,
  MDBHamburgerToggler
} from 'mdbreact';
import { BrowserRouter as Router } from 'react-router-dom';

class Mobilemenu extends Component {
  state = {
    collapse1: false,
    collapseID: ''
  }

  toggleCollapse = collapseID => () => {
    this.setState(prevState => ({ collapseID: (prevState.collapseID !== collapseID ? collapseID : '') }));
  }

  toggleSingleCollapse = collapseId => {
    this.setState({
      ...this.state,
      [collapseId]: !this.state[collapseId]
    });
  }

  render() {
    return (
      <Router>
        <MDBNavbar>
          <MDBHamburgerToggler id="hamburger1" onClick={() => this.toggleSingleCollapse('collapse1')} />
          <MDBCollapse isOpen={this.state.collapse1} navbar>
            <MDBNavbarNav left>
              <MDBNavItem active>
                <MDBDropdown>
                  <li className="menu-hasdropdown"><a href={process.env.PUBLIC_URL + '/'} > Home </a>
                  </li>
                </MDBDropdown>
              </MDBNavItem>
              <MDBNavItem>
                <li className="menu-hasdropdown"><a href={process.env.PUBLIC_URL + '/aboutus'} >About Us</a>
                </li>
              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <span>Hire Candidate</span>
                  </MDBDropdownToggle>

                  <MDBDropdownMenu>
                  <MDBDropdown>
                  <MDBDropdownToggle nav caret>
                    <span>Web Developers</span>
                  </MDBDropdownToggle>
                  <MDBDropdownMenu>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + '/react-developer'}>ReactJS Developer</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + '/angular-developer'}>AngularJS Developer</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + '/node-developer'}>NodeJS Developer</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + '/php-developer'}>PHP Developer</MDBDropdownItem>
                    <MDBDropdownItem href={process.env.PUBLIC_URL + '/wordpress-developer'}>WordPress Developer</MDBDropdownItem>                        </MDBDropdownMenu>
                </MDBDropdown>
                <MDBDropdown>
                      <MDBDropdownToggle nav caret>
                        <span>Designers</span>
                      </MDBDropdownToggle>
                      <MDBDropdownMenu>
                        <MDBDropdownItem href={process.env.PUBLIC_URL + '/graphic-developer'}>Graphic Designer</MDBDropdownItem>
                        <MDBDropdownItem href={process.env.PUBLIC_URL + '/uxui-developer'}>UX/UI Designer</MDBDropdownItem>
                       </MDBDropdownMenu>
                    </MDBDropdown>
                  </MDBDropdownMenu>
                </MDBDropdown>

              </MDBNavItem>
              <MDBNavItem>
                <MDBDropdown>
                  <li className="menu-hasdropdown"><a href={process.env.PUBLIC_URL + '/services'} >Services</a></li>
                </MDBDropdown>
              </MDBNavItem>

              <MDBNavItem>
                <MDBDropdown>
                  <li className="menu-hasdropdown"><a href={process.env.PUBLIC_URL + '/contact'} >Contact Us</a></li>
                </MDBDropdown>
              </MDBNavItem>

            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
      </Router>
    );
  }
}

export default Mobilemenu;