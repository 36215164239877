import React, { useRef,useEffect } from 'react';
import emailjs from '@emailjs/browser';
import Header from '../layout/Header';
import ReactGA from 'react-ga';
import useAnalyticsEventTracker from '../comman/useAnalyticsEventTracker';


export const Contact =(props)=> {

    const gaEventTracker = useAnalyticsEventTracker(' Contect us')

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_lxucn2v', 'template_wp7p3ig', form.current, 'au38rfQ7kwr4bgnoP')
      .then((result) => {
          console.log(result.text , "success");
          form.current.reset(); // reset the input fields on success
        alert("Thank you for your message!");
          
      }, (error) => {
          console.log(error.text);
      });
  };
    
        return (
            <div className="site-main">
                <Header />
                {/* page-title */}
                <div style={{ backgroundImage: "url(images/Feature/feature-1.jpg)", backgroundSize: "cover" }} className="ttm-page-title-row">
                    <div className="container-xl">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="title-box text-center">
                                    <div className="page-title-heading">
                                        <h1 className="title">Contact Us</h1>
                                    </div>{/* /.page-title-captions */}
                                </div>
                            </div>{/* /.col-md-12 */}
                        </div>{/* /.row */}
                    </div>{/* /.container */}
                </div>
                {/* page-title end*/}
                <section className="ttm-row zero-padding-section clearfix">
                    <div className="container-xl">
                        <div className="row no-gutters">{/* row */}
                            <div className="col-lg-5">
                                <div className="spacing-9">
                                    {/* section title */}
                                    <div className="section-title with-desc clearfix">
                                        <div className="title-header">
                                            <h5>Come Visit Us At</h5>
                                            <h2 className="title">Our Address</h2>
                                        </div>
                                    </div>{/* section title end */}
                                    <div className="row">
                                        <div className="col-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box style2 left-icon icon-align-top">
                                                <div className="featured-icon">{/* featured-icon */}
                                                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                        <i className="ti-location-pin" />
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">{/* featured title */}
                                                        <h5>Office Address</h5>
                                                    </div>
                                                    <div className="featured-desc">{/* featured desc */}
                                                        <p>Delhi-110092, India</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>             
                                    {/* separator */}
                                    <div className="separator">
                                        <div className="sep-line mt-4 mb-4" />
                                    </div>
                                    {/* separator */}
                                    <div className="row">
                                        <div className="col-12">
                                            {/* featured-icon-box */}
                                            <div className="featured-icon-box style2 left-icon icon-align-top">
                                                <div className="featured-icon">{/* featured-icon */}
                                                    <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md rounded-circle">
                                                        <i className="ti ti-email" />
                                                    </div>
                                                </div>
                                                <div className="featured-content">
                                                    <div className="featured-title">{/* featured title */}
                                                        <h5>Our Email</h5>
                                                    </div>
                                                    <div className="featured-desc">{/* featured desc */}
                                                        <p>info@todomist.in</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <div className="spacing-10 ttm-bgcolor-grey ttm-bg ttm-col-bgcolor-yes ttm-right-span">
                                    <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                                        <div className="ttm-bg-layer-inner" />
                                    </div>
                                    {/* section title */}
                                    <div className="section-title with-desc clearfix">
                                        <div className="title-header">
                                            <h5>Send Message</h5>
                                            <h2 className="title">Drop Us A Line</h2>
                                        </div>
                                    </div>{/* section title end */}
                                    <form ref={form} onSubmit={sendEmail} id="ttm-quote-form" className="row ttm-quote-form clearfix" method="post"  action="mailto:hr@todomist.in">
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input name="name" type="text" className="form-control bg-white" placeholder="Full Name*" required="required" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input name="phone" type="text" placeholder="Phone Number*" required="required" className="form-control bg-white" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input name="address" type="text" placeholder="Email Address*" required="required" className="form-control bg-white" />
                                            </div>
                                        </div>
                                        <div className="col-sm-6 col-md-6">
                                            <div className="form-group">
                                                <input name="subject" type="text" placeholder="Subject" required="required" className="form-control bg-white" />
                                            </div>
                                        </div>
                                        <div className="col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <textarea name="message" rows={5} placeholder="Write A Massage..." required="required" className="form-control bg-white" defaultValue={""} />
                                            </div>
                                        </div>
                                        <div className="col-md-12">
                                            <div className="text-left">
                                                <button type="submit" id="submit" onClick={()=>gaEventTracker('Cantect Button')} className="mt-3 ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor" value>
                                                    Submit Quote
                                                </button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                        {/* row end */}
                    </div>
                </section>
                <section className="ttm-row map-section res-991-p-0 clearfix">
                    <div className="map-wrapper">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d56029.85189400689!2d77.25209972143561!3d28.633785634110552!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfb560fb0d32d%3A0x3976ab216e9cb88a!2sNew%20Delhi%2C%20Delhi%20110092!5e0!3m2!1sen!2sin!4v1680266296415!5m2!1sen!2sin"></iframe>
                    </div>
                </section>
            </div>
        )
    }



export default Contact;

