import React from 'react';
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
import CountUp from 'react-countup';
import Sidebar from '../../services/Sidebar';
import Slider from 'react-slick';
import Header from '../../layout/Header';

export const Graphic_designer = (props) => {
    var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,

        responsive: [{

            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {

            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {

            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };

    return (
        <div className="site-main">
            <Header />
            {/* page-title */}
            <div className="ttm-page-title-row ">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                    <h1 className="title">Hire Graphic Designer</h1>
                                </div>{/* /.page-title-captions */}
                                <div className="breadcrumb-wrapper">
                                    <p>Our team includes talented graphic designers who can create visually appealing designs that capture your brand's essence and effectively communicate your message to your target audience.</p>
                                </div>
                            </div>
                        </div>{/* /.col-md-12 */}
                    </div>{/* /.row */}
                </div>{/* /.container */}
            </div>
            <section className="ttm-row our-client-section res-991-p-0 ttm-bgcolor-skincolor clearfix">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-12 text-center">
                            <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={5}>
                                <div className="ttm-box-col-wrapper">
                                    <div className="client-box ttm-box-view-boxed-logo">
                                        <div className="client">
                                            <div className="ttm-client-logo-tooltip" data-tooltip="Adobe">
                                                <img className="img-fluid" src="images/Compeny-logo/Company=Adobe.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-box-col-wrapper">
                                    <div className="client-box ttm-box-view-boxed-logo">
                                        <div className="client">
                                            <div className="ttm-client-logo-tooltip" data-tooltip="Fivetran">
                                                <img className="img-fluid" src="images/Compeny-logo/Company=Fivetran.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-box-col-wrapper">
                                    <div className="client-box ttm-box-view-boxed-logo">
                                        <div className="client">
                                            <div className="ttm-client-logo-tooltip" data-tooltip="Airbnb">
                                                <img className="img-fluid" src="images/Compeny-logo/Company=Airbnb.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-box-col-wrapper">
                                    <div className="client-box ttm-box-view-boxed-logo">
                                        <div className="client">
                                            <div className="ttm-client-logo-tooltip" data-tooltip="Airbnb">
                                                <img className="img-fluid" src="images/Compeny-logo/Company=Opendoor.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="ttm-box-col-wrapper">
                                    <div className="client-box ttm-box-view-boxed-logo">
                                        <div className="client">
                                            <div className="ttm-client-logo-tooltip" data-tooltip="Airtable">
                                                <img className="img-fluid" src="images/Compeny-logo/Company=Airtable.png" alt="image" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Slider>

                        </div>
                    </div>
                </div>
            </section>
            <section className="ttm-row process-section clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              {/* section title */}
              <div className="section-title text-center with-desc clearfix">
                <div className="title-header">
                  <h5>how it work</h5>
                  <h2 className="title">Check Out Our Work <span>Process</span></h2>
                </div>
              </div>{/* section title end */}
            </div>
          </div>
          {/* row */}
          <div className="row">
            <div className="col-lg-12">
              <div className="ttm-processbox-wrapper">
                <div className="ttm-processbox text-center">
                  <div className="ttm-box-image">
                    <img className="img-fluid" alt="step-one" title="step-one" src="images/process/prosses-4.png" />
                    <div className="process-num">
                      <span className="number">1</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title"><h5>Choose a Service</h5></div>
                    <div className="ttm-box-description">You need to choose a service regarding project for accomplishment.</div>
                  </div>
                </div>
                <div className="ttm-processbox text-center">
                  <div className="ttm-box-image">
                    <img className="img-fluid" alt="step-two" title="step-two" src="images/process/prosses-1.png" />
                    <div className="process-num">
                      <span className="number">2</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title"><h5>Request a Meeting</h5></div>
                    <div className="ttm-box-description">After choosing service the next step is to request a meeting with managers.</div>
                  </div>
                </div>
                <div className="ttm-processbox text-center">
                  <div className="ttm-box-image">
                    <img className="img-fluid" alt="step-three" title="step-three" src="images/process/prosses-2.png" />
                    <div className="process-num">
                      <span className="number">3</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title"><h5>Receive Custom Plan</h5></div>
                    <div className="ttm-box-description">We have optimised plan according to your budget for each service.</div>
                  </div>
                </div>
                <div className="ttm-processbox text-center res-991-mb-0">
                  <div className="ttm-box-image">
                    <img className="img-fluid" alt="step-four" title="step-four" src="images/process/prosses-3.png" />
                    <div className="process-num">
                      <span className="number">4</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title"><h5>Let’s Make it Happen</h5></div>
                    <div className="ttm-box-description">It’s time to us to deliver your project on time with satisfaction.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>{/* row end */}
        </div>
      </section>
            {/* page-title end*/}
            <div className="sidebar ttm-sidebar ttm-bgcolor-white clearfix">
                <div className="container-xl">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-9 content-area order-lg-2">
                            {/* ttm-service-single-content-are */}
                            {/* ttm-service-single-content-are */}
                            <div className="ttm-service-single-content-area">
                                <div className="ttm_single_image-wrapper mb-35">
                                    <img className="img-fluid" src="images/Hire-Candidate-1200_800/hire_can-1.jpg" alt="single-img-twelve" />
                                </div>
                                <div className="ttm-service-description">
                                    <h3>Hire Graphic Designer from Todomist</h3>
                                    <p>A graphic designer can help you create visually compelling designs for your branding and marketing materials, such as logos, business cards, brochures, and social media graphics.</p>
                                    <p>They have a keen eye for aesthetics and can help you create a cohesive visual identity for your brand.</p>
                                    <div className="row no-gutters box-shadow mb-35">
                                        <div className="col-md-4 col-sm-4">
                                            {/*ttm-fid*/}
                                            <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                                                <div className="ttm-fid-left">
                                                    <div className="ttm-fid-icon-wrapper">
                                                        <i className="flaticon flaticon-global-1 ttm-textcolor-skincolor" />
                                                    </div>
                                                </div>
                                                <div className="ttm-fid-contents text-left">
                                                    <h4 className="ttm-fid-inner">
                                                        <CountUp start={0} end={9} duration={30} /> +
                                                    </h4>
                                                    <h3 className="ttm-fid-title">Years Experience</h3>
                                                </div>
                                            </div>{/* ttm-fid end*/}
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            {/*ttm-fid*/}
                                            <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                                                <div className="ttm-fid-left">
                                                    <div className="ttm-fid-icon-wrapper">
                                                        <i className="flaticon flaticon-padlock ttm-textcolor-skincolor" />
                                                    </div>
                                                </div>
                                                <div className="ttm-fid-contents text-left">
                                                    <h4 className="ttm-fid-inner">
                                                        <CountUp start={0} end={590} duration={30} /> +
                                                    </h4>
                                                    <h3 className="ttm-fid-title">Happy Clients</h3>
                                                </div>
                                            </div>{/* ttm-fid end*/}
                                        </div>
                                        <div className="col-md-4 col-sm-4">
                                            {/*ttm-fid*/}
                                            <div className="ttm-fid inside ttm-fid-view-lefticon style2">
                                                <div className="ttm-fid-left">
                                                    <div className="ttm-fid-icon-wrapper">
                                                        <i className="flaticon flaticon-developer ttm-textcolor-skincolor" />
                                                    </div>
                                                </div>
                                                <div className="ttm-fid-contents text-left">
                                                    <h4 className="ttm-fid-inner">
                                                        <CountUp start={0} end={815} duration={30} /> +
                                                    </h4>
                                                    <h3 className="ttm-fid-title">New Candidate</h3>
                                                </div>
                                            </div>{/* ttm-fid end*/}
                                        </div>
                                    </div>
                                     <h3>Why Choose Us?</h3>
                                    <div className="row mt-35 ">
                                        <div className="overflow-auto col-lg-12 ">
                                            <div className="row m-0 box-shadow spacing-11">
                                                <div className="col-md-6 col-lg-12 col-xl-6">
                                                    <div className="ttm_single_image-wrapper">
                                                        <img className="img-fluid" src="images/Hire-Candidate-1200_800/385_353/choose-1.png" alt="single-img-thirteen" />
                                                    </div>
                                                </div>

                                                <div className="col-md-6 col-lg-12 col-xl-6">
                                                     <ul className="ttm-list ttm-list-style-icon">
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Skip sourcing</span>  <p>Eradicate the long and tedious process of sourcing candidates to find the right fit.</p></li>                                                      
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">No pre or post-hiring responsibilities</span> <p>From helping you build a great team to managing them (HR and Payrolls) - we will take care of everything.</p></li>                                                       
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Save on Salary</span> <p>Todomist saves you up to 40% on salary over local talent without compromising on quality</p></li>                                                      
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Beat Talent Crunch </span>   <p> Access a network of experienced candidates and hire the top Angular Developers for app and web development.</p></li>                                                    
                                                        <li><i className="fa fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">No Obligation Interview</span>   <p> Interviewing a candidate does not obligate you to hire them. We recommend other profiles if you're not satisfied. No questions asked.</p></li>                                                   
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <h3 className="mt-35">Frequently Asked Questions</h3>
                                    {/* acadion 
                                    */}
                                    <Accordion className="accordion style2">
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                What does a graphic designer do?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="mb-0">
                                                A graphic designer creates visual designs for various mediums such as print, digital, and advertising. They use software tools like Adobe Photoshop and Illustrator to create designs that communicate a specific message or idea to the audience.
                                          </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                What skills should a graphic designer have?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="mb-0">
                                                A graphic designer should have a strong foundation in design principles such as color theory, typography, layout, and composition. They should also be proficient in industry-standard software such as Adobe Creative Suite. Communication and collaboration skills are also important for working with clients and team members.
                                           </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                What is the difference between a graphic designer and a web designer?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="mb-0">
                                                While both graphic designers and web designers create visual designs, a graphic designer primarily focuses on static designs for print, packaging, or branding, while a web designer creates dynamic designs for websites or mobile applications.
                                           </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                How much does a graphic designer cost?
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="mb-0">
                                                The cost of hiring a graphic designer can vary depending on factors such as their level of experience, the complexity of the project, and the region they are located in. Some designers charge an hourly rate, while others charge a flat fee or project-based pricing.
                                            </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                        <AccordionItem>
                                            <AccordionItemHeading>
                                                <AccordionItemButton>
                                                What is the process of working with a graphic designer
                                                </AccordionItemButton>
                                            </AccordionItemHeading>
                                            <AccordionItemPanel>
                                                <p className="mb-0">
                                                The process of working with a graphic designer typically involves an initial consultation to discuss project requirements, followed by a proposal outlining the scope of work, timeline, and cost. Once the proposal is accepted, the designer will present initial design concepts, and the client will provide feedback for revisions until the final design is approved
                                             </p>
                                            </AccordionItemPanel>
                                        </AccordionItem>
                                    </Accordion>
                                </div>
                            </div>
                            {/* ttm-service-single-content-are end */}
                        </div>
                        <div className="col-lg-3 widget-area">
                            <Sidebar />
                        </div>
                    </div>{/* row end */}
                </div>
            </div>
        </div>
    )
}



export default Graphic_designer;