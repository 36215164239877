import React, { useState } from 'react'
import { Menu } from './menu';
import Mobile_menu from './Mobilemenu'


export function Header() {
    const [show, setShow] = useState(false);

    const toggle = () => setShow(!show);

    return (
        <header id="masthead" className="header ttm-header-style-01">
            {/* ttm-topbar-wrapper */}
            <div className="ttm-topbar-wrapper ttm-bgcolor-darkgrey ttm-textcolor-white clearfix">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-lg-5">
                            <ul className="top-contact text-left">
                                <li><i className="fas fa-map-marker-alt" />&nbsp;&nbsp; Delhi-110092, India</li>
                                <li><i className="far fa-envelope" />&nbsp;&nbsp; <a to="mailto:info@todomist.in">info@todomist.in</a></li>
                            </ul>
                        </div>
                        <div className="col-lg-7">
                            <div className="topbar-right text-right d-flex justify-content-end">
                                {/* <ul className="top-contact">
                                    <li><i className="far fa-clock" />&nbsp;&nbsp;Office Hour: 08:00am - 6:00pm</li>
                                </ul> */}
                                <div className="ttm-social-links-wrapper list-inline">
                                    <ul className="social-icons">
                                        <li><a href='https://www.linkedin.com/company/todomist/' className=" tooltip-bottom" data-tooltip="Facebook"><i className="fab fa-facebook-f" /></a>
                                        </li>
                                        <li><a href='https://www.linkedin.com/company/todomist/' className=" tooltip-bottom" data-tooltip="Twitter"><i className="fab fa-twitter" /></a>
                                        </li>
                                        <li><a href='https://www.linkedin.com/company/todomist/' className=" tooltip-bottom" data-tooltip="Flickr"><i className="fab fa-flickr" /></a>
                                        </li>
                                        <li><a href='https://www.linkedin.com/company/todomist/' className=" tooltip-bottom" data-tooltip=" Linkdin"><i className="fab fa-linkedin-in" /></a>
                                        </li>
                                    </ul>
                                </div>
                                <div className="header-btn">
                                    <a href={process.env.PUBLIC_URL + '/contact'} className="ttm-btn ttm-btn-size-md ttm-bgcolor-skincolor" to="#">Get A Quote</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* ttm-topbar-wrapper end */}
            {/* ttm-header-wrap */}
            <div className="ttm-header-wrap">
                <div id="navbar" className="ttm-stickable-header clearfix">
                    <div className="site-header-menu">
                        <div className="container-xl">
                            {/* site-branding */}
                            <div className="site-branding">
                                <a className="home-link" href={process.env.PUBLIC_URL + '/'} title="Todomist" rel="home">
                                    <img id="logo-img" className="img-fluid" alt="logo" src="images/todomist_logo.png" />
                                </a>
                            </div>
                            {/* site-branding end*/}
                            {/*site-navigation */}
                            <div className="site-navigation">
                                
                                <Menu />
                                <div className="mobilemenu"><Mobile_menu/></div>
                            </div>
                            {/*site-navigation end*/}
                        </div>
                    </div>
                </div>
            </div>
            {/* ttm-header-wrap end*/}
        </header>
    )
}


export default Header;