import React, { Component } from 'react';
import Slider from 'react-slick';
import Header from '../layout/Header';

export const Services = (props) => {

    var slick_slider = {
        dots: false,
        arrow: false,
        autoplay: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,

        responsive: [{

            breakpoint: 1199,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {

            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 3
            }
        },
        {

            breakpoint: 767,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 2
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }]
    };
    return (
        <div className="site-main">
            <Header />
            {/* page-title */}
            <div  style={{ backgroundImage: "url(images/Feature/feature-1.jpg)", backgroundSize: "cover"  }}  className="ttm-page-title-row">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                    <h1 className="title">Our Services</h1>
                                </div>{/* /.page-title-captions */}
                            </div>
                        </div>{/* /.col-md-12 */}
                    </div>{/* /.row */}
                </div>{/* /.container */}
            </div>
            {/* page-title end*/}
            {/* about-services-section */}
            <section className="ttm-row about-services-section clearfix">
                <div className="container-xl">
                    <div className="row align-items-center">{/* row */}
                        <div className="col-lg-6">
                            <div className="position-relative">
                                {/* ttm_single_image-wrapper */}
                                <div className="ttm_single_image-wrapper text-sm-center">
                                    <img className="img-fluid" src="images/Service/550_604/service-2.png" title="single-img-ten" alt="single-img-ten" />
                                </div>{/* ttm_single_image-wrapper end */}
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="res-991-mt-30">
                                {/* section title */}
                                <div className="section-title with-desc clearfix">
                                    <div className="title-header">
                                        <h2 className="title">Would you like to take over<br /> our <span>web service</span></h2>
                                    </div>
                                    <div className="title-desc">
                                        <p>Our approach to software development is cantered on collaboration and transparency. We work closely with our clients to ensure that we are aligned on project goals, timelines, and budget. Throughout the development process, we provide regular updates and communicate openly with our clients to ensure that they are always informed.</p>
                                    </div>
                                </div>{/* section title end */}
                                {/* row */}
                                <div className="row mt-25">
                                    <div className="col-12">
                                        {/* featured-icon-box */}
                                        <div className="featured-icon-box iconalign-before-heading">
                                            <div className="featured-content">{/* featured-content */}
                                                <div className="ttm-icon ttm-bgcolor-grey style1">
                                                    <i className="ti ti-info ttm-num" />
                                                </div>
                                                <div className="featured-title">{/* featured-title */}
                                                    <h5>Web Development Service</h5>
                                                </div>
                                            </div>
                                            <div className="featured-desc">{/* featured-desc */}
                                                <p>Our sales team is dedicated to finding and securing projects for our company's services, including custom application development such as the Retailer Application and KLIP. We utilize platforms like Upwork and Fiverr to source large, from-scratch projects that leverage our expertise in MERN technologies and WordPress.</p>
                                            </div>
                                        </div>{/* featured-icon-box */}
                                    </div>
                                </div>
                                {/* separator */}
                                <div className="separator">
                                    <div className="sep-line mt-4 mb-3" />
                                </div>
                                {/* separator */}
                                <div className="row">
                                    <div className="col-12">
                                        {/* featured-icon-box */}
                                        <div className="featured-icon-box iconalign-before-heading">
                                            <div className="featured-content">{/* featured-content */}
                                                <div className="ttm-icon ttm-bgcolor-grey style1">
                                                    <i className="ti ti-info ttm-num" />
                                                </div>
                                                <div className="featured-title">{/* featured-title */}
                                                    <h5>Outsourcing Employee Service</h5>
                                                </div>
                                            </div>
                                            <div className="featured-desc">{/* featured-desc */}
                                                <p>Our company offers outsourcing services for employees on a contract basis, providing skilled developers to other companies for a specified period. Companies may choose to hire candidates from Todomist instead of hiring directly for a variety of reasons.</p>
                                            </div>
                                        </div>{/* featured-icon-box */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* row end */}
                </div>
            </section>
            {/* about-services-section end */}
            {/* services-slide-section */}
            <section className="ttm-row services-slide-section ttm-bgcolor-grey ttm-bg ttm-bgimage-yes bg-img14 clearfix">
                <div className="ttm-row-wrapper-bg-layer ttm-bg-layer"></div>
                <div className="container-xl">
                    <div className="row align-items-center">{/* row */}
                        <div className="col-lg-9 col-xl-9 col-md-9">
                            {/* section-title */}
                            <div className="section-title style2 clearfix">
                                <div className="title-header">
                                    <h5>Fast Growing Information &amp; technologies Estates.</h5>
                                    <h2 className="title">Providing High-Performance Services For Multiple <span>Information &amp; Technologies!</span></h2>
                                </div>
                            </div>{/* section-title end */}
                        </div>
                        {/* <div className="col-lg-3 col-xl-3 col-md-3">
                            <div className="text-md-right">
                                <a href={process.env.PUBLIC_URL + '/services'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-darkgrey mb-5" > Read More </a>
                            </div>
                        </div> */}
                    </div>
                    <Slider className="position-relative z-1 row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={3}>
                        <div className="ttm-box-col-wrapper">
                            {/* featured-imagebox-services */}
                            <div className="featured-imagebox featured-imagebox-services style1">
                                <div className="featured-thumbnail">{/* featured-thumbnail */}
                                    <img className="img-fluid" src="images/Service/654_490/service-2.png" alt="image" />
                                </div>
                                <div className="featured-content box-shadow">
                                    <div className="featured-title">{/* featured-title */}
                                        <h5><a href={process.env.PUBLIC_URL + '/it_consultancy'}>IT Consultancy</a></h5>
                                    </div>
                                    <div className="featured-desc">{/* featured-title */}
                                        <p> Expertise in IT consultancy for the IT companies, for their different working areas Web-app related.</p>
                                    </div>
                                    <a className="ttm-btn ttm-btn-size-sm ttm-textcolor-skincolor btn-inline ttm-icon-btn-right mt-2" href={process.env.PUBLIC_URL + '/it_consultancy'}>Read More <i className="ti ti-angle-double-right" /></a>
                                </div>
                            </div>
                            {/* featured-imagebox-services */}
                        </div>
                        <div className="ttm-box-col-wrapper">
                            {/* featured-imagebox-services */}
                            <div className="featured-imagebox featured-imagebox-services style1">
                                <div className="featured-thumbnail">{/* featured-thumbnail */}
                                    <img className="img-fluid" src="images/Service/654_490/service-1.png" alt="image" />
                                </div>
                                <div className="featured-content box-shadow">
                                    <div className="featured-title">{/* featured-title */}
                                        <h5><a href={process.env.PUBLIC_URL + '/web_development'}>Web Development</a></h5>
                                    </div>
                                    <div className="featured-desc">{/* featured-title */}
                                        <p>Our sales team is dedicated to finding and securing projects for our company's services, including custom application</p>
                                    </div>
                                    <a className="ttm-btn ttm-btn-size-sm ttm-textcolor-skincolor btn-inline ttm-icon-btn-right mt-2" href={process.env.PUBLIC_URL + '/web_development'}>Read More <i className="ti ti-angle-double-right" /></a>
                                </div>
                            </div>
                            {/* featured-imagebox-services */}
                        </div>
                        <div className="ttm-box-col-wrapper">
                            {/* featured-imagebox-services */}
                            <div className="featured-imagebox featured-imagebox-services style1">
                                <div className="featured-thumbnail">{/* featured-thumbnail */}
                                    <img className="img-fluid" src="images/Service/654_490/service-3.png" alt="image" />
                                </div>
                                <div className="featured-content box-shadow">
                                    <div className="featured-title">{/* featured-title */}
                                        <h5><a href={process.env.PUBLIC_URL + '/it_manegement'}>IT Management</a></h5>
                                    </div>
                                    <div className="featured-desc">{/* featured-title */}
                                        <p>We provide all kind of IT management services of in the according to on their organization prioritie.</p>
                                    </div>
                                    <a className="ttm-btn ttm-btn-size-sm ttm-textcolor-skincolor btn-inline ttm-icon-btn-right mt-2" href={process.env.PUBLIC_URL + '/it_manegement'}>Read More <i className="ti ti-angle-double-right" /></a>
                                </div>
                            </div>
                        </div>
                        {/* featured-imagebox-services */}
                        <div className="ttm-box-col-wrapper">
                            <div className="featured-imagebox featured-imagebox-services style1">
                                <div className="featured-thumbnail">{/* featured-thumbnail */}
                                    <img className="img-fluid" src="images/Service/654_490/service-4.png" alt="image" />
                                </div>
                                <div className="featured-content box-shadow">
                                    <div className="featured-title">{/* featured-title */}
                                        <h5><a href={process.env.PUBLIC_URL + '/graphic_design'}>Graphic Design</a></h5>
                                    </div>
                                    <div className="featured-desc">{/* featured-title */}
                                        <p>Creating visually appealing and effective designs to communicate your message to your audience.</p>
                                    </div>
                                    <a className="ttm-btn ttm-btn-size-sm ttm-textcolor-skincolor btn-inline ttm-icon-btn-right mt-2" href={process.env.PUBLIC_URL + '/graphic_design'}>Read More <i className="ti ti-angle-double-right" /></a>
                                </div>
                            </div>
                            {/* featured-imagebox-services */}
                        </div>
                    </Slider>
                </div>
            </section>
            {/* services-slide-section end */}
            {/* portfolio-section */}
            <section className="ttm-row zero-padding-section mt_230  res-991-mt-0 position-relative clearfix">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12">
                            {/* section title */}
                            <div className="section-title text-center with-desc clearfix">
                                <div className="title-header">
                                    <h2 className="title">Check out our <span>Portfolio</span></h2>
                                </div>
                            </div>{/* section title end */}
                        </div>
                    </div>
                    {/* row */}
                    <div className="row multi-columns-row ttm-boxes-spacing-10px ttm-bgcolor-white">
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/Service/Portfolio/port 1.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="category">
                                        <p>Business , Campaign</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a href={process.env.PUBLIC_URL + '/graphic_design'}>Graphic Design</a></h5>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/Service/Portfolio/port 2.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="category">
                                        <p>Campaign , Technology</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a href={process.env.PUBLIC_URL + '/it_manegement'}>IT Management</a></h5>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/Service/Portfolio/port 3.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="category">
                                        <p>Corporate , Workshop</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a href={process.env.PUBLIC_URL + '/cloud_services'}>Cloud Services</a></h5>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/Service/Portfolio/port 4.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="category">
                                        <p>Innovation , Technology</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a href={process.env.PUBLIC_URL + '/web_development'}>Web Development</a></h5>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/Service/Portfolio/port 5.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="category">
                                        <p>Corporate</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a href={process.env.PUBLIC_URL + '/it_consultancy'}>IT Consultancy</a></h5>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-6 col-sm-6">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/Service/Portfolio/port 6.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="category">
                                        <p>Service </p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a href={process.env.PUBLIC_URL + '/services'}>All Service</a></h5>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox */}
                        </div>
                    </div>{/* row end */}
                </div>
            </section>
            {/* portfolio-section end */}
            {/* our-partner-section */}
           
        </div>
    )
}



export default Services;