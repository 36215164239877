import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';

export const Footer = (props) => {
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();


        emailjs.sendForm('service_lxucn2v', 'template_wp7p3ig', form.current, 'au38rfQ7kwr4bgnoP')
            .then((result) => {
                console.log(result.text);
                form.current.reset();
            }, (error) => {
                console.log(error.text);
            });
    };

    return (
        <footer className="footer widget-footer clearfix">
            {props.children}
            <div style={{ backgroundImage: "url(images/Home/todomist_home-2.jpg)", backgroundSize: "cover" }} className="first-footer ttm-bgcolor-skincolor ttm-bg ttm-bgimage-yes bg-img1">
                <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
                <div className="container-xl ">
                    <div className="row align-items-md-center">
                        <div className="col-lg-4 col-md-4 col-sm-12 order-md-2">
                            <div className="footer-logo text-sm-center">
                                <img data-src="images/footer-logo.png" className="img-fluid" alt="footer-logo" src="images/todomist_logo_w.png" />
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 order-md-1 res-767-mt-20">
                            <div className="text-left">
                                {/*  featured-icon-box */}
                                <div className="featured-icon-box left-icon icon-align-top">
                                    <div className="featured-icon">{/*  featured-icon */}
                                        <div className="ttm-icon ttm-textcolor-white ttm-icon_element-size-md">
                                            <i className="ti ti-location-pin" />{/*  ttm-icon */}
                                        </div>
                                    </div>
                                    <div className="featured-content">{/*  featured-content */}
                                        <div className="featured-desc">
                                            <p>Delhi - 110092 , India</p>
                                        </div>
                                    </div>
                                </div>{/*  featured-icon-box END */}
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4 col-sm-6 order-md-3 res-767-mt-20">
                            <div className="text-sm-right">
                                <a href={'mailto:info@todomist.in'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"><i className="far fa-envelope" />info@todomist.in</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ backgroundImage: "url(images/Home/todomist_home-2.jpg)", backgroundSize: "cover" }} className="first-footer ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes bg-img1">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer" />
                <div className="container-xl ">
                    <div className="row flex align-items-md-center">
                        <div className="col-xs-12 col-sm-12 col-md-6 mt-0 ml-4  col-lg-3 widget-area">
                            <div className="widget widget_text  clearfix">
                                <h3 className="widget-title">About Our Company</h3>
                                <div className="textwidget widget-text">
                                    Our company offers a range of services to help businesses succeed in the digital age. Our expertise in web development includes proficiency in the MERN stack, which consists of MongoDB, Express, ReactJS, and NodeJS.
                                </div>
                                <div className="quicklink-box">
                                    {/*  featured-icon-box */}
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 mt-1 col-md-6 col-lg-3 ml-5 widget-area">
                            <div className="widget link-widget clearfix">
                                <h3 className="widget-title">Quick Links</h3>
                                <ul id="menu-footer-services ">
                                    <li><a href={process.env.PUBLIC_URL + '/contact'}>Make Appointment</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/services'}>Customer Services</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/aboutus'}>About Company</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/contact'}>Free Consultation</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/contact'}>Meet Our Experts</a></li>
                                    <li><a href={process.env.PUBLIC_URL + '/aboutus'}>Our Business Growth</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-xs-12 col-sm-12 col-md-6 mt-2 col-lg-4 ml-5 widget-area res-767-mb-40">
                            <div className="widget flicker_widget clearfix">
                                <h3 className="widget-title">News Letter</h3>
                                <div className="textwidget widget-text">
                                    Sign up today for hints, tips and the latest product and service
                                    <form ref={form} onSubmit={sendEmail} id="subscribe-form" className="newsletter-form" method="post" action="#" data-mailchimp="true">
                                        <div className="mailchimp-inputbox clearfix" id="subscribe-content">
                                            <i className="fa fa-envelope" />
                                            <input type="email" name="email" placeholder="Email Address.." required />
                                            <input type="submit" value="" />
                                        </div>
                                        <div id="subscribe-msg" />
                                    </form>
                                    <h5>Follow Us On</h5>
                                    <div className="social-icons circle social-hover">
                                        <ul className="list-inline">
                                            <li className="social-facebook"><a href='https://www.linkedin.com/company/todomist/' className="tooltip-top rounded-circle" target="_blank" data-tooltip="Facebook"><i className="fab fa-facebook-f" /></a></li>
                                            <li className="social-twitter"><a href='https://www.linkedin.com/company/todomist/' className="tooltip-top rounded-circle" target="_blank" data-tooltip="Twitter"><i className="fab fa-twitter" /></a></li>
                                            <li className="social-flickr"><a href='https://www.linkedin.com/company/todomist/' className=" tooltip-top rounded-circle" target="_blank" data-tooltip="flickr"><i className="fab fa-flickr" /></a></li>
                                            <li className="social-linkedin"><a href='https://www.linkedin.com/company/todomist/' className=" tooltip-top rounded-circle" target="_blank" data-tooltip="Linkdin"><i className="fab fa-linkedin-in" /></a></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bottom-footer-text ttm-bgcolor-darkgrey ttm-textcolor-white">
                <div className="container-xl">
                    <div className="row copyright align-items-center res-767-text-center">
                        <div className="col-md-6">
                            <div>
                                <span>Copyright © 2023&nbsp;<a to="/">Todomist</a> by <a to="/" target="_blank">Todomist</a></span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="text-md-right res-767-mt-10">
                                <div className="d-lg-inline-flex">
                                    <ul id="menu-footer-menu" className="footer-nav-menu">
                                        <li><a href={process.env.PUBLIC_URL + '/aboutus'} >About Us</a></li>
                                        <li><a href={process.env.PUBLIC_URL + '/services'} >Services</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
