import React, { Component } from 'react';
import 'react-tabs/style/react-tabs.css';
import Header from '../layout/Header';
import Detailbox from './Detail_box';

export const Graphic_design = (props) => {
    return (
        <div className="site-main">
            <Header />
            {/* page-title */}
            <div className="ttm-page-title-row">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                    <h1 className="title">Graphic Design</h1>
                                </div>{/* /.page-title-captions */}                              
                            </div>
                        </div>{/* /.col-md-12 */}
                    </div>{/* /.row */}
                </div>{/* /.container */}
            </div>
            <section className="ttm-row project-single-section clearfix">
                <div className="container-xl">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-6 col-xl-8">
                            {/* ttm_single_image-wrapper */}
                            <div className="ttm_single_image-wrapper mb-35">
                                <img className="img-fluid" src="images/Hire-Candidate-1200_800/hire_can-5.jpg" title="single-img-twenty" alt="single-img-twenty" />
                            </div>{/* ttm_single_image-wrapper end */}
                        </div>
                        <div className="col-lg-6 col-xl-4">
                            <Detailbox />
                        </div>
                    </div>
                    {/* row end*/}
                    {/* row */}
                    <div className="row">
                        <div className="col-12">
                            <h4> Innovative Interfaces for Graphic design</h4>
                            <p>Graphic design could be a virtual reality (VR) platform that allows designers to create and manipulate three-dimensional graphics in real-time. With the use of a VR headset and controllers, designers can sculpt and shape their designs using natural hand gestures and movements, while moving around their creations in a 360-degree environment. This platform could also offer collaborative design features, allowing multiple designers to work on the same project simultaneously, and integrate with other design tools and software for a seamless workflow. Overall, this interface has the potential to revolutionize graphic design, providing a powerful and immersive tool for creating stunning three-dimensional designs.</p>
                            <h4>You may also know how it uses in IT field</h4>
                            <p>In the IT field, graphic design is an essential component that involves creating visual elements and designs for a wide range of digital media and applications. Graphic designers create interfaces for websites, software applications, and mobile apps, making them visually appealing and easy to use. They also create logos, brochures, and other marketing materials to promote businesses' products and services. Motion graphics and animation are also used to create interactive media that can be used in advertisements and product demos.</p>
                        </div>
                        <div className="col-md-4">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style3 left-icon icon-align-top">
                                <div className="featured-icon">{/* featured-icon */}
                                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon flaticon-algorithm" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">{/* featured title */}
                                        <h5>Expert Team</h5>
                                    </div>
                                    <div className="featured-desc">{/* featured desc */}
                                        <p>Our expert team consists of highly skilled professionals with a passion for delivering exceptional results.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style3 left-icon icon-align-top">
                                <div className="featured-icon">{/* featured-icon */}
                                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon flaticon-padlock" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">{/* featured title */}
                                        <h5>Free Consultation</h5>
                                    </div>
                                    <div className="featured-desc">{/* featured desc */}
                                        <p>Our free consultation service offers a valuable opportunity for individuals to receive expert advice and guidance without any obligation.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style3 left-icon icon-align-top">
                                <div className="featured-icon">{/* featured-icon */}
                                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon flaticon-process-1" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">{/* featured title */}
                                        <h5>Lowest Cost</h5>
                                    </div>
                                    <div className="featured-desc">{/* featured desc */}
                                        <p>Providing high-quality service at the lowest possible cost.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <h4 className="mt-35">Service Result &amp; Benefits of Service</h4>
                            <p className="mb-35 res-991-mb-20">Graphic designers work with web developers to create visually appealing websites that are easy to navigate, and they create visual content for social media platforms to help businesses build their online presence. Overall, graphic design plays a crucial role in the IT field by creating visually appealing and user-friendly digital media and applications.</p>
                        </div>

                    </div>{/* row end*/}
                    {/* row */}
                    <div className="row ttm-pf-single-related-wrapper ">
                        <div className="col-12">
                            <h4 className="mb-30">Related Projects</h4>
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/Service/Portfolio/port 4.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="category">
                                        <p>Business , Campaign</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a href={process.env.PUBLIC_URL + '/web_development'}>Web Development</a></h5>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/Service/Portfolio/port 3.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="category">
                                        <p>Campaign , Technology</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a href={process.env.PUBLIC_URL + '/cloud_services'}>Cloud Services</a></h5>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/Service/Portfolio/port 2.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="category">
                                        <p>Corporate , Workshop</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a href={process.env.PUBLIC_URL + '/it_manegement'}>IT Manegement</a></h5>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox */}
                        </div>
                    </div>{/* row end*/}
                </div>
            </section>
        </div>
    )
}



export default Graphic_design;