import React, { Component } from 'react';

export const Detailbox =(props)=> {
   
        return (
            <div className="ttm-pf-single-detail-box mb-35">
                <div className="ttm-pf-single-title">
                <h5>Service Info</h5>
                </div>
                <p>Our company offers a range of services to help businesses succeed in the digital age. Our expertise in web development includes proficiency in the MERN stack, which consists of MongoDB, Express, ReactJS, and NodeJS. We can design and develop custom websites that are tailored to your unique needs and goals. </p>
            </div>
            )
    }



export default Detailbox;