import React, { Component } from 'react';
import 'react-tabs/style/react-tabs.css';
import Header from '../layout/Header';
import Detailbox from './Detail_box';

export const Web_development = (props) => {
    return (
        <div className="site-main">
            <Header />
            {/* page-title */}
            <div className="ttm-page-title-row">
                <div className="container-xl">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="title-box text-center">
                                <div className="page-title-heading">
                                    <h1 className="title">Web Development</h1>
                                </div>{/* /.page-title-captions */}
                            </div>
                        </div>{/* /.col-md-12 */}
                    </div>{/* /.row */}
                </div>{/* /.container */}
            </div>
            <section className="ttm-row project-single-section clearfix">
                <div className="container-xl">
                    {/* row */}
                    <div className="row">
                        <div className="col-lg-6 col-xl-8">
                            {/* ttm_single_image-wrapper */}
                            <div className="ttm_single_image-wrapper mb-35">
                                <img className="img-fluid" src="images/Hire-Candidate-1200_800/hire_can-1.jpg" title="single-img-twenty" alt="single-img-twenty" />
                            </div>{/* ttm_single_image-wrapper end */}
                        </div>
                        <div className="col-lg-6 col-xl-4">
                            <Detailbox />
                        </div>
                    </div>
                    {/* row end*/}
                    {/* row */}
                    <div className="row">
                        <div className="col-12">
                            <h4> Innovative Interfaces for Web Development</h4>
                            <p>Our sales team is dedicated to finding and securing projects for our company's services, including custom application development such as the Retailer Application and KLIP. We utilize platforms like Upwork and Fiverr to source large, from-scratch projects that leverage our expertise in MERN technologies and WordPress.</p>
                            <h4>You may also know how it uses in IT field</h4>
                            <p>Additionally, we rely on referrals from satisfied clients to expand our client base and secure new projects. Our team is committed to delivering high-quality work and providing exceptional customer service to our clients, regardless of the project's size or complexity.</p>
                        </div>
                        <div className="col-md-4">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style3 left-icon icon-align-top">
                                <div className="featured-icon">{/* featured-icon */}
                                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon flaticon-algorithm" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">{/* featured title */}
                                        <h5>Expert Team</h5>
                                    </div>
                                    <div className="featured-desc">{/* featured desc */}
                                        <p>Our expert team consists of highly skilled professionals with a passion for delivering exceptional results.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style3 left-icon icon-align-top">
                                <div className="featured-icon">{/* featured-icon */}
                                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon flaticon-padlock" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">{/* featured title */}
                                        <h5>Free Consultation</h5>
                                    </div>
                                    <div className="featured-desc">{/* featured desc */}
                                        <p>Our free consultation service offers a valuable opportunity for individuals to receive expert advice and guidance without any obligation.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            {/* featured-icon-box */}
                            <div className="featured-icon-box style3 left-icon icon-align-top">
                                <div className="featured-icon">{/* featured-icon */}
                                    <div className="ttm-icon ttm-textcolor-skincolor ttm-icon_element-size-lg">
                                        <i className="flaticon flaticon-process-1" />
                                    </div>
                                </div>
                                <div className="featured-content">
                                    <div className="featured-title">{/* featured title */}
                                        <h5>Lowest Cost</h5>
                                    </div>
                                    <div className="featured-desc">{/* featured desc */}
                                        <p>Providing high-quality service at the lowest possible cost.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <h4 className="mt-35">Service Result &amp; Benefits of Service</h4>
                            <p className="mb-35 res-991-mb-20">Contact us today to learn more about how we can help bring your ideas to life through our custom application development services.</p>
                        </div>
                        <div className="col-12">
                            {/* ttm_single_image-wrapper */}
                            <div className="ttm_single_image-wrapper mt-35">
                                <img className="img-fluid" src="images/Project 1171_548/project-5.jpg" title="single-img-twentyone" alt="single-img-twentyone" />
                            </div>{/* ttm_single_image-wrapper end */}
                        </div>
                        {/* separator */}
                        <div className="col-12">
                            <div className="separator">
                                <div className="sep-line mt-35 mb-35" />
                            </div>
                        </div>
                        {/* separator */}

                    </div>{/* row end*/}
                    {/* row */}
                    <div className="row ttm-pf-single-related-wrapper ">
                        <div className="col-12">
                            <h4 className="mb-30">Related Projects</h4>
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/Service/Portfolio/port 1.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="category">
                                        <p>Business , Campaign</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a href={process.env.PUBLIC_URL + '/graphic_design'}>Graphic Design</a></h5>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/Service/Portfolio/port 3.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="category">
                                        <p>Campaign , Technology</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a href={process.env.PUBLIC_URL + '/cloud_services'}>Cloud Services</a></h5>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox */}
                        </div>
                        <div className="ttm-box-col-wrapper col-lg-4 col-md-4 col-sm-12">
                            {/* featured-imagebox */}
                            <div className="featured-imagebox featured-imagebox-portfolio style2 mb-20">
                                {/* featured-thumbnail */}
                                <div className="featured-thumbnail">
                                    <img className="img-fluid" src="images/Service/Portfolio/port 2.jpg" alt="image" />
                                </div>{/* featured-thumbnail end*/}
                                <div className="featured-content">
                                    <div className="category">
                                        <p>Corporate , Workshop</p>
                                    </div>
                                    <div className="featured-title">
                                        <h5><a href={process.env.PUBLIC_URL + '/it_manegement'}>IT Manegement</a></h5>
                                    </div>
                                </div>
                            </div>{/* featured-imagebox */}
                        </div>
                    </div>{/* row end*/}
                </div>
            </section>
        </div>
    )
}



export default Web_development;