import React from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route } from 'react-router-dom';

export const Menu = (props) => {

    return (
        <nav id="menu" role="navigation" className="menu">
            <ul>
                <li className="menu-hasdropdown"><a href={process.env.PUBLIC_URL + '/'} > Home </a>
                </li>
                <li className="menu-hasdropdown"><a href={process.env.PUBLIC_URL + '/aboutus'} >About Us</a>
                </li>
                <li className="menu-hasdropdown">
                <a>Hire Candidate</a>
                    <ul className="menu-dropdown">
                        <li className="menu-hasdropdown menu-hasflyout ">
                                <a> Web Developers</a>
                                <ul className="menu-dropdown">
                                <li><a href={process.env.PUBLIC_URL + '/react-developer'}>ReactJS Developer</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/angular-developer'}>AngularJS Developer</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/node-developer'}> NodeJS Developer</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/php-developer'}> PHP Developer</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/wordpress-developer'}>  WordPress Developer</a></li>
                                </ul>
                            </li>
                            <li className="menu-hasdropdown menu-hasflyout">
                                <a>Designers</a>
                                <ul className="menu-dropdown">
                                <li><a href={process.env.PUBLIC_URL + '/graphic-developer'}>Graphic Designer</a></li>
                                <li><a href={process.env.PUBLIC_URL + '/uxui-developer'}>UX/UI Designer</a></li>
                                </ul>
                            </li>
                    </ul>
                </li>
                <li className="menu-hasdropdown"><a href={process.env.PUBLIC_URL + '/services'} >Services</a></li>
                <li className="menu-hasdropdown"><a href={process.env.PUBLIC_URL + '/contact'} >Contact Us</a>
                </li>
            </ul>
        </nav>

    )
}
