import React from 'react';
import { createBrowserHistory } from 'history';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Link } from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';
// Layout
import { Footer } from './components/layout/Footer';
// pages
import Home from './components/Home';
import Aboutus from './components/pages/Aboutus';
import Services from './components/pages/Services';
import Our_expert from './components/pages/Our_expert';
import Faq from './components/pages/Faq';
import Contact from './components/pages/Contact';
import Error from './components/pages/Error';
import React_developer from './components/hire-candidate/web-developer/React_developer';
import AngularJS from './components/hire-candidate/web-developer/AngularJS';
import NodeJS from './components/hire-candidate/web-developer/NodeJS';
import PHP from './components/hire-candidate/web-developer/PHP';
import WordPress from './components/hire-candidate/web-developer/WordPress';
import Project_style1 from './components/project/Project_style1';
import Single_style1 from './components/project/Single_style1';
import It_consultancy from './components/project/It_consultancy';
import Web_development from './components/project/Web_development';
import Cloud_services from './components/project/Cloud_services';
import Graphic_design from './components/project/Graphic_design';
import It_manegement from './components/project/It_manegement';
import ScrollToTop from './components/layout/Gotop';
import Graphic_designer from './components/hire-candidate/designers/Graphic_designer';
import Uxui from './components/hire-candidate/designers/Uxui';

// Google Tag Maneger
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
  gtmId: 'GTM-NRRKQ2G'
}

TagManager.initialize(tagManagerArgs)


// Google Analytics
const TRACTING_ID="G-FD9C1V4X87";
ReactGA.initialize(TRACTING_ID);

const appHistory = {
  // basename: process.env.PUBLIC_URL
};
const history = createBrowserHistory(appHistory);
function App() {
   // GTM Code
   window.dataLayer.push({
    event: 'pageview'
  });
  
//secount type for adding google analytics
const location = useLocation();
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="page">
      <Router history={history}>
        <div id="preloader">
          <div id="status">&nbsp;</div>
        </div>
        <ScrollToTop />
        <Route exact path='/' component={Home} />
        <Route path="/aboutus" component={Aboutus} />
        <Route path="/services" component={Services} />
        <Route path="/our_expert" component={Our_expert} />
        <Route path="/faq" component={Faq} />
        <Route path="/Contact" component={Contact} />
        <Route path="/error" component={Error} />
        <Route path="/react-developer" component={React_developer} />
        <Route path="/angular-developer" component={AngularJS} />
        <Route path="/node-developer" component={NodeJS} />
        <Route path="/php-developer" component={PHP} />
        <Route path="/wordpress-developer" component={WordPress} />
        <Route path="/graphic-developer" component={Graphic_designer} />
        <Route path="/uxui-developer" component={Uxui} />
        <Route path="/project_style1" component={Project_style1} />
        <Route path="/single_style1" component={Single_style1} />
        <Route path="/it_consultancy" component={It_consultancy} />
        <Route path="/web_development" component={Web_development} />
        <Route path="/cloud_services" component={Cloud_services} />
        <Route path="/graphic_design" component={Graphic_design} />
        <Route path="/it_manegement" component={It_manegement} />
        <Footer />
      </Router>
    </div>
  );
}

export default App;
