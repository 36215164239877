import React, { useState } from 'react';
import Slider from 'react-slick';
import { MDBProgress } from 'mdbreact';
import { Header } from './layout/Header';
import { CarouselPage } from './banner/Home1_banner';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';



const images = [
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg',
  'https://via.placeholder.com/600X750/444444.jpg'
];

export const Home = (props) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  var slick_slider = {
    dots: false,
    arrow: false,
    autoplay: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,

    responsive: [{

      breakpoint: 1199,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {

      breakpoint: 1024,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3
      }
    },
    {

      breakpoint: 680,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2
      }
    },
    {
      breakpoint: 575,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1
      }
    }]
  };

  return (
    <div className="site-main">
      <Header />
      {/* home banner */}
      <CarouselPage />{/* home banner end*/}
      {/* service-section */}
      <section className="ttm-row service-section position-relative z-1 clearfix">
        <div className="container-xl">
          <div className="row no-gutters featured-icon-box-style1-row ttm-bgcolor-darkgrey">
            <div className="col-md-4">
              <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white h-100">
                <div className="featured-icon">{/*  featured-icon */}
                  <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                    <i className="flaticon flaticon-system" />{/*  ttm-icon */}
                  </div>
                </div>
                <div className="featured-content">{/*  featured-content */}
                  <div className="featured-title">{/*  featured-title */}
                    <h5>Trusted Services</h5>
                  </div>
                  <div className="featured-desc">{/*  featured-desc */}
                    <p>We Are Trusted Our Customers</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4">
              {/*  featured-icon-box */}
              <div className="featured-icon-box left-icon style1 ttm-bgcolor-darkgrey ttm-textcolor-white">
                <div className="featured-icon">{/*  featured-icon */}
                  <div className="ttm-icon ttm-bgcolor-skincolor ttm-icon_element-size-md">
                    <i className="flaticon flaticon-clock" />{/*  ttm-icon */}
                  </div>
                </div>
                <div className="featured-content">{/*  featured-content */}
                  <div className="featured-title">{/*  featured-title */}
                    <h5>24/7 Support</h5>
                  </div>
                  <div className="featured-desc">{/*  featured-desc */}
                    {/* <p>+91 7678579865</p> */}
                  </div>
                </div>
              </div>{/*  featured-icon-box END */}
            </div>
            <div className="col-md-4">
              {/*  featured-icon-box */}
              <div className="featured-icon-box left-icon style1 ttm-bgcolor-skincolor ttm-textcolor-white">
                <div className="featured-icon">{/*  featured-icon */}
                  <div className="ttm-icon ttm-bgcolor-darkgrey ttm-icon_element-size-md">
                    <i className="flaticon flaticon-developer" />{/*  ttm-icon */}
                  </div>
                </div>
                <div className="featured-content">{/*  featured-content */}
                  <div className="featured-title">{/*  featured-title */}
                    <h5>Expert &amp; Professional</h5>
                  </div>
                  <div className="featured-desc">{/*  featured-desc */}
                    <p>We Are Trusted Our Customers</p>
                  </div>
                </div>
              </div>{/*  featured-icon-box END */}
            </div>
          </div>
        </div>
      </section>
      {/* service-section end */}
      {/* aboutus-section */}
      <section className="ttm-row aboutus-section clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-lg-6">
              {/* section title */}
              <div className="section-title with-desc clearfix">
                <div className="title-header">
                  <h5>About us</h5>
                  <h2 className="title">Grow your business with <span>Todomist</span></h2>
                </div>
                <div className="title-desc">
                  <p>We are a team of experienced and skilled software developers who are dedicated to providing high-quality software solutions to our clients. We specialize in developing custom software applications, mobile apps, web development, and enterprise software systems</p>
                  <p>Our team comprises of talented developers, designers, project managers, and quality assurance engineers who work together to ensure that our clients receive the best possible software solutions.  We will deliver your projects on time and within budget. Contact us today to discuss your project, and let us help you take your business to the next level.</p>
                </div>
              </div>{/* section title end */}
              {/* row */}
              <div className="row no-gutters mt-4">
                <div className="col-md-6 col-lg-6 col-sm-6">
                  <ul className="ttm-list ttm-list-style-icon">
                    <li><i className="far fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Web And Mobile Application </span></li>
                    <li><i className="far fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">New Domain Registration</span></li>
                    <li><i className="far fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Web & Email Hosting Services</span></li>
                  </ul>
                </div>
                <div className="col-md-6 col-lg-6 col-sm-6">
                  <ul className="ttm-list ttm-list-style-icon">
                    <li><i className="far fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Improving Our Website Design</span></li>
                    <li><i className="far fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Web &amp; Email Hosting Services </span></li>
                    <li><i className="far fa-check-circle ttm-textcolor-skincolor" /><span className="ttm-list-li-content">Artificial Intelligence Web App</span></li>
                  </ul>
                </div>
              </div>{/* row END*/}
              {/* separator */}
              <div className="separator">
                <div className="sep-line mt-4 mb-4" />
              </div>

            </div>
            <div className="col-lg-6 res-991-mt-40 res-991-text-center">
              <div className="position-relative res-991-mt-30">
                {/* ttm_single_image-wrapper */}
                <div className="ttm_single_image-wrapper">
                  <img className="img-fluid" src="images/About/about-1.jpg" title="single-img-one" alt="single-img-one" />
                </div>{/* ttm_single_image-wrapper end */}
                {/*featured-icon-box*/}
                <div className="about-overlay-01">
                  <p className="mb-0">Indian IT Solutions Association IIA.</p>
                </div>
                {/* featured-icon-box end*/}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* aboutus-section end */}
      {/* history-section */}
      <section className="ttm-row history-section bg-img3 ttm-bgcolor-grey ttm-bg ttm-bgimage-yes clearfix">
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
        <div className="container-xl">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              {/* section title */}
              <div className="section-title with-desc text-center clearfix">
                <div className="title-header">
                  <h5>Our Services</h5>
                  <h2 className="title">We run all kinds of services in form of <span>Information &amp; Technologies</span></h2>
                </div>
              </div>{/* section title end */}
            </div>
          </div>
          <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider}>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">{/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-business-and-finance" />{/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />{/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title"><h5>Web Development</h5></div>{/* historybox-title  */}
                  <div className="ttm-historybox-description">{/* description  */}
                    Our sales team is dedicated to finding and securing projects for our company's services.
                  </div>
                  <a href={process.env.PUBLIC_URL + '/web_development'} className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20">Read More <i className="ti ti-angle-double-right" /></a>
                </div>
              </div>
            </div>
            <div style={{ backgroundImage: "url(images/Home/todomist_home-5.jpg)", backgroundSize: "cover" }} className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">{/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-computer" />{/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />{/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title"><h5>IT Management</h5></div>{/* historybox-title  */}
                  <div className="ttm-historybox-description">{/* description  */}
                    We provide all kind of IT management services of in the according to on their organization prioritie.
                  </div>
                  <a href={process.env.PUBLIC_URL + '/it_manegement'} className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" >Read More <i className="ti ti-angle-double-right" /></a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">{/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-data" />{/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />{/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title"><h5>Graphic Design</h5></div>{/* historybox-title  */}
                  <div className="ttm-historybox-description">{/* description  */}
                    Creating visually appealing and effective designs to communicate your message to your audience.
                  </div>
                  <a href={process.env.PUBLIC_URL + '/graphic_design'} className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" >Read More <i className="ti ti-angle-double-right" /></a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">{/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-global-1" />{/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />{/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title"><h5>Cloud Services</h5></div>{/* historybox-title  */}
                  <div className="ttm-historybox-description">{/* description  */}
                    We develop, migrate and work on web applications to ensure that they run on capably on clouds.
                  </div>
                  <a href={process.env.PUBLIC_URL + '/cloud_services'} className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" >Read More <i className="ti ti-angle-double-right" /></a>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="ttm-history-box-wrapper">{/* ttm-history-box-wrapper  */}
                <div className="ttm-history-box-icon-wrapper">{/* ttm-history-box-icon-wrapper  */}
                  {/*  featured-icon-box */}
                  <div className="featured-icon-box">
                    <div className="featured-icon">{/*  featured-icon */}
                      <div className="ttm-icon ttm-bgcolor-white ttm-icon_element-size-md">
                        <i className="flaticon flaticon-business-and-finance" />{/*  ttm-icon */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ttm-history-box-border" />{/* ttm-history-box-border  */}
                <div className="ttm-history-box-details ttm-bgcolor-white">
                  <div className="ttm-historybox-title"><h5>IT Consultancy</h5></div>{/* historybox-title  */}
                  <div className="ttm-historybox-description">{/* description  */}
                    Expertise in IT consultancy for the IT companies, for their different working areas Web-app related.
                  </div>
                  <a href={process.env.PUBLIC_URL + '/it_consultancy'} className="ttm-btn ttm-btn-size-sm ttm-btn-color-skincolor btn-inline ttm-icon-btn-right mt-20" >Read More <i className="ti ti-angle-double-right" /></a>
                </div>
              </div>
            </div>
          </Slider>

          <div className="row">
            <div className="col-md-12 text-center mt-5 res-991-mt-30">
              <p className="mb-0">Don’t hesitate, contact us for better help and services. <strong><u><a href={process.env.PUBLIC_URL + '/services'} className="ttm-textcolor-darkgrey"> View more Services</a></u></strong></p>
            </div>
          </div>
        </div>
      </section>
      {/* history-section end*/}
      {/* zero-padding-section */}
      <section className="ttm-row zero-padding-section mt_80 res-991-mt-0 ">
        <div className="container-xl">
          <div className="row no-gutters">
            <div className="col-lg-6">
              {/* col-bg-img-one */}
              <div className="col-bg-img-one ttm-bg  ttm-left-span res-991-h-auto">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                  <div className="ttm-bg-layer-inner" />
                </div>

              </div>
              {/* Responsive View image */}
              <img style={{ display: "block" }} src="images/Home/todomist_home-5.jpg" className="ttm-equal-height-image img" alt="col-bgimage-1" />
            </div>
            <div className="col-lg-6 z-9">
              <div className="ttm-bgcolor-skincolor ttm-textcolor-white position-relative ttm-right-span">
                <div className="spacing-1">
                  {/* section title */}
                  <div className="section-title with-desc clearfix">
                    <div className="title-header">
                      <h5>Why Todomist</h5>
                      <h2 className="title">We deal with the aspects of professional <span>IT Services</span></h2>
                    </div>
                    <div className="title-desc">
                      <p>Our IT services converge business and technology experts to help to manage business processes of all categories.</p>
                    </div>
                  </div>{/* section title end */}
                  <div className="row mt-40">
                    <div className="col-12">
                      {/* featured-icon-box */}
                      <div className="featured-icon-box style2 left-icon icon-align-top">
                        <div className="featured-icon">{/* featured-icon */}
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                            <i className="flaticon flaticon-process" />
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">{/* featured title */}
                            <h5>Web Development Service</h5>
                          </div>
                          <div className="featured-desc">{/* featured desc */}
                            <p>Our sales team is dedicated to finding and securing projects for our company's services</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* separator */}
                  <div className="separator">
                    <div className="sep-line mt-4 mb-4" />
                  </div>
                  {/* separator */}
                  <div className="row">
                    <div className="col-12">
                      {/* featured-icon-box */}
                      <div className="featured-icon-box style2 left-icon icon-align-top">
                        <div className="featured-icon">{/* featured-icon */}
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                            <i className="flaticon flaticon-system" />
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">{/* featured title */}
                            <h5>Outsource Few Component &amp; or parts</h5>
                          </div>
                          <div className="featured-desc">{/* featured desc */}
                            <p>Our company offers outsourcing services for specific components or parts of projects on a project basis</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* separator */}
                  <div className="separator">
                    <div className="sep-line mt-4 mb-4" />
                  </div>
                  {/* separator */}
                  <div className="row">
                    <div className="col-12">
                      {/* featured-icon-box */}
                      <div className="featured-icon-box style2 left-icon icon-align-top">
                        <div className="featured-icon">{/* featured-icon */}
                          <div className="ttm-icon ttm-icon_element-border ttm-icon_element-color-white ttm-icon_element-size-md">
                            <i className="flaticon flaticon-data" />
                          </div>
                        </div>
                        <div className="featured-content">
                          <div className="featured-title">{/* featured title */}
                            <h5>Outsourcing Employee Service</h5>
                          </div>
                          <div className="featured-desc">{/* featured desc */}
                            <p>Our company offers outsourcing services for employees on a contract basis, providing skilled developers to other companies for a specified period</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* zero-padding-section end */}
      {/* team-section */}
      <section className="ttm-row team-section res-991-pt-0 clearfix">
        <div className="container-xl">
          <div className="row align-items-center mb-5">
            <div className="col-lg-5 res-991-text-center">
              <div className="section-title style2 mb-0 clearfix">
                <div className="title-header mb-0">
                  <h5>About us</h5>
                  <h2 className="title">Meet our <span>Leadership</span></h2>
                </div>
              </div>
            </div>
            <div className="col-lg-5 res-991-text-center">
              <div className="title-desc">The World of Inspiring Success and Connections among People and Organizations is distinguished by its positive attributes.</div>
            </div>
            <div className="col-lg-2 text-right res-991-text-center res-991-mt-20">
              <a href={process.env.PUBLIC_URL + '/our_expert'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-darkgrey"> Read More </a>
            </div>
          </div>
          <Slider className="row slick_slider ttm-boxes-spacing-30px" {...slick_slider} slidesToShow={3}>
            <div className="ttm-box-col-wrapper">
              <div className="featured-imagebox featured-imagebox-team style1">
                <div className="featured-thumbnail">{/* featured-thumbnail */}
                  <img className="img-fluid" src="images/Home/Team/Team 1.jpg" alt="" />
                  <div className="ttm-social-links-wrapper">
                    <ul className="social-icons list-inline">{/* social-icons */}
                      <li className="social-facebook"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-facebook-f" /></a></li>
                      <li className="social-twitter"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-twitter" /></a></li>
                      <li className="social-linkedin"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-linkedin-in" /></a></li>
                      <li className="social-googleplus"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-google-plus-g" /></a></li>
                    </ul>
                  </div>
                </div>
                <div className="featured-content box-shadow">
                  <div className="featured-title">{/* featured-title */}
                    <h5><a href={process.env.PUBLIC_URL + '/our_expert'}>Robert Liamss</a></h5>
                  </div>
                  <p className="category">Web Designer</p>{/* category */}
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="featured-imagebox featured-imagebox-team style1">
                <div className="featured-thumbnail">{/* featured-thumbnail */}
                  <img className="img-fluid" src="images/Home/Team/Team 2.jpg" alt="" />
                  <div className="ttm-social-links-wrapper">
                    <ul className="social-icons list-inline">{/* social-icons */}
                      <li className="social-facebook"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-facebook-f" /></a></li>
                      <li className="social-twitter"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-twitter" /></a></li>
                      <li className="social-linkedin"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-linkedin-in" /></a></li>
                      <li className="social-googleplus"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-google-plus-g" /></a></li>
                    </ul>
                  </div>
                </div>
                <div className="featured-content box-shadow">
                  <div className="featured-title">{/* featured-title */}
                    <h5><a href={process.env.PUBLIC_URL + '/our_expert'}>Jessica Ethan</a></h5>
                  </div>
                  <p className="category">Project Manager</p>{/* category */}
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="featured-imagebox featured-imagebox-team style1">
                <div className="featured-thumbnail">{/* featured-thumbnail */}
                  <img className="img-fluid" alt="" src="images/Home/Team/Team 3.jpg" />
                  <div className="ttm-social-links-wrapper">
                    <ul className="social-icons list-inline">{/* social-icons */}
                      <li className="social-facebook"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-facebook-f" /></a></li>
                      <li className="social-twitter"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-twitter" /></a></li>
                      <li className="social-linkedin"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-linkedin-in" /></a></li>
                      <li className="social-googleplus"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-google-plus-g" /></a></li>
                    </ul>
                  </div>
                </div>
                <div className="featured-content box-shadow">
                  <div className="featured-title">{/* featured-title */}
                    <h5><a href={process.env.PUBLIC_URL + '/our_expert'}>Amanda Brown</a></h5>
                  </div>
                  <p className="category">Consultant Officer</p>{/* category */}
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="featured-imagebox featured-imagebox-team style1">
                <div className="featured-thumbnail">{/* featured-thumbnail */}
                  <img className="img-fluid" alt="" src="images/Home/Team/Team 4.jpg" />
                  <div className="ttm-social-links-wrapper">
                    <ul className="social-icons list-inline">{/* social-icons */}
                      <li className="social-facebook"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-facebook-f" /></a></li>
                      <li className="social-twitter"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-twitter" /></a></li>
                      <li className="social-linkedin"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-linkedin-in" /></a></li>
                      <li className="social-googleplus"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-google-plus-g" /></a></li>
                    </ul>
                  </div>
                </div>
                <div className="featured-content box-shadow">
                  <div className="featured-title">{/* featured-title */}
                    <h5><a href={process.env.PUBLIC_URL + '/our_expert'}>Jennifer Garcia</a></h5>
                  </div>
                  <p className="category">IT Service Officer</p>{/* category */}
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              <div className="featured-imagebox featured-imagebox-team style1">
                <div className="featured-thumbnail">{/* featured-thumbnail */}
                  <img className="img-fluid" alt="" src="images/Home/Team/Team 5.jpg" />
                  <div className="ttm-social-links-wrapper">
                    <ul className="social-icons list-inline">{/* social-icons */}
                      <li className="social-facebook"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-facebook-f" /></a></li>
                      <li className="social-twitter"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-twitter" /></a></li>
                      <li className="social-linkedin"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-linkedin-in" /></a></li>
                      <li className="social-googleplus"><a href='https://www.linkedin.com/company/todomist/'><i className="fab fa-google-plus-g" /></a></li>
                    </ul>
                  </div>
                </div>
                <div className="featured-content box-shadow">
                  <div className="featured-title">{/* featured-title */}
                    <h5><a href={process.env.PUBLIC_URL + '/our_expert'}>Robert Liam</a></h5>
                  </div>
                  <p className="category">Web Designer</p>{/* category */}
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </section>
      {/* team-section end*/}
      {/* first-row-title-section */}
      <section style={{ backgroundImage: "url(images/Home/todomist_home-6.jpg)", backgroundSize: "cover" }} className="ttm-row first-row-title-section ttm-bgcolor-darkgrey ttm-textcolor-white ttm-bg ttm-bgimage-yes bg-img clearfix">
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
        <div className="container-xl">
          <div className="row">{/* row */}
            <div className="col-lg-8 offset-lg-2">
              <div className="text-center">
                {/* section-title */}
                <div className="section-title with-desc text-center clearfix">
                  <div className="title-header">
                    <h2 className="title">We Provide Outsourced IT Services For Small &amp; <span className="ttm-textcolor-white">Mid-Sized Business</span></h2>
                  </div>
                  <div className="title-desc">Tailored to your unique business needs, our IT services ensure hassle-free access to top-quality solutions. What We Do</div>
                </div>{/* section-title end */}
                <div className="mt-5">
                  <a href={process.env.PUBLIC_URL + '/services'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white">More Services</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* first-row-title-section end*/}
      {/* testimonial-section */}
      <section className="ttm-row zero-padding-section clearfix">
        <div className="container-xl">
          <div className="row no-gutters mt_100 res-991-mt-0">
            <div className="col-md-6">
              <div className="ttm-bgcolor-darkgrey ttm-textcolor-white z-11 position-relative h-100">
                <div className="layer-content">{/* layer-content */}
                  <div className="spacing-2">{/* spacing-2 */}
                    {/* section title */}
                    <div className="section-title ttm with-desc clearfix">
                      <div className="title-header">
                        <h5>What We Do</h5>
                        <h2 className="title">We help you to grow your business <span className="ttm-textcolor-white">Exponentially</span></h2>
                      </div>
                    </div>{/* section title end */}
                    {/* progress-bar */}
                    <div className="ttm-progress-bar">
                      <div className="progress-bar-title float-left">UI/UX Design</div>{/* progress-bar-title */}
                      <label className="float-right">90%</label>
                      <MDBProgress className="w-100" material value={90}></MDBProgress>
                    </div>
                    {/* progress-bar END */}
                    {/* progress-bar */}
                    <div className="ttm-progress-bar">
                      <div className="progress-bar-title float-left">App Development</div>{/* progress-bar-title */}
                      <label className="float-right">80%</label>
                      <MDBProgress className="w-100" material value={80}></MDBProgress>
                    </div>
                    {/* progress-bar END */}
                  </div>{/* spacing-2 END */}
                </div>{/* layer-content END */}
              </div>
            </div>
            <div className="col-md-6">
              <div className="col-bg-img-two ttm-col-bgimage ttm-bg ttm-col-bgcolor-yes ttm-bgcolor-skincolor ttm-textcolor-white">
                <div className="ttm-col-wrapper-bg-layer ttm-bg-layer">
                  <div className="ttm-bg-layer-inner" />
                </div>
                <div className="layer-content">
                  <div className="testimonial-slide style1">
                    <Slider className="row slick_slider" arrows={false} dots={true}>
                      <div className="col-lg-12">
                        <div className="testimonials text-center">
                          <div className="testimonial-content">{/* testimonials-content */}
                            <div className="testimonial-avatar">
                              <div className="testimonial-img">{/* testimonials-img */}
                                <img className="img-center" alt="testimonial-img" src="images/Avatars_150/Avatar-a.png" />
                              </div>
                            </div>
                            <blockquote>We have thoroughly reviewed and discussed every single detail to ensure that the given project
                              developed to the highest standard possible</blockquote>
                            <div className="ttm-ratting-star">
                              <i className="fa fa-star" />&nbsp;
                              <i className="fa fa-star" />&nbsp;
                              <i className="fa fa-star" />&nbsp;
                              <i className="fa fa-star" />&nbsp;
                              <i className="fa fa-star" />
                            </div>
                            <div className="testimonial-caption">{/* testimonials-caption */}
                              <h6>Callie</h6>
                              <label>Web Devloper</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="testimonials text-center">
                          <div className="testimonial-content">{/* testimonials-content */}
                            <div className="testimonial-avatar">
                              <div className="testimonial-img">{/* testimonials-img */}
                                <img className="img-center" src="images/Avatars_150/Avatar-b.png" alt="testimonial-img" />
                              </div>
                            </div>
                            <blockquote>Our team has diligently examined and deliberated on every aspect of the project to guarantee its
                              development to the utmost level of excellence.</blockquote>
                            <div className="ttm-ratting-star">
                              <i className="fa fa-star" />&nbsp;
                              <i className="fa fa-star" />&nbsp;
                              <i className="fa fa-star" />&nbsp;
                              <i className="fa fa-star" />&nbsp;
                              <i className="fa fa-star" />
                            </div>
                            <div className="testimonial-caption">{/* testimonials-caption */}
                              <h6>Hualing</h6>
                              <label>Web Devloper</label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="testimonials text-center">
                          <div className="testimonial-content">{/* testimonials-content */}
                            <div className="testimonial-avatar">
                              <div className="testimonial-img">{/* testimonials-img */}
                                <img className="img-center" alt="testimonial-img" src="images/Avatars_150/Avatar-c.png" />
                              </div>
                            </div>
                            <blockquote>We Todomist delivered a given project after studying thoroughly for better client`s references.</blockquote>
                            <div className="ttm-ratting-star">
                              <i className="fa fa-star" />&nbsp;
                              <i className="fa fa-star" />&nbsp;
                              <i className="fa fa-star" />&nbsp;
                              <i className="fa fa-star" />&nbsp;
                              <i className="fa fa-star" />
                            </div>
                            <div className="testimonial-caption">{/* testimonials-caption */}
                              <h6>Victor Williamson</h6>
                              <label>Web Devloper</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Slider>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* testimonial-section end */}
      {/* process-section */}
      <section className="ttm-row process-section clearfix">
        <div className="container-xl">
          <div className="row">
            <div className="col-md-12">
              {/* section title */}
              <div className="section-title text-center with-desc clearfix">
                <div className="title-header">
                  <h5>how it work</h5>
                  <h2 className="title">Check Out Our Work <span>Process</span></h2>
                </div>
              </div>{/* section title end */}
            </div>
          </div>
          {/* row */}
          <div className="row">
            <div className="col-lg-12">
              <div className="ttm-processbox-wrapper">
                <div className="ttm-processbox text-center">
                  <div className="ttm-box-image">
                    <img className="img-fluid" alt="step-one" title="step-one" src="images/process/prosses-4.png" />
                    <div className="process-num">
                      <span className="number">1</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title"><h5>Choose a Service</h5></div>
                    <div className="ttm-box-description">You need to choose a service regarding project for accomplishment.</div>
                  </div>
                </div>
                <div className="ttm-processbox text-center">
                  <div className="ttm-box-image">
                    <img className="img-fluid" alt="step-two" title="step-two" src="images/process/prosses-1.png" />
                    <div className="process-num">
                      <span className="number">2</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title"><h5>Request a Meeting</h5></div>
                    <div className="ttm-box-description">After choosing service the next step is to request a meeting with managers.</div>
                  </div>
                </div>
                <div className="ttm-processbox text-center">
                  <div className="ttm-box-image">
                    <img className="img-fluid" alt="step-three" title="step-three" src="images/process/prosses-2.png" />
                    <div className="process-num">
                      <span className="number">3</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title"><h5>Receive Custom Plan</h5></div>
                    <div className="ttm-box-description">We have optimised plan according to your budget for each service.</div>
                  </div>
                </div>
                <div className="ttm-processbox text-center res-991-mb-0">
                  <div className="ttm-box-image">
                    <img className="img-fluid" alt="step-four" title="step-four" src="images/process/prosses-3.png" />
                    <div className="process-num">
                      <span className="number">4</span>
                    </div>
                  </div>
                  <div className="featured-content">
                    <div className="featured-title"><h5>Let’s Make it Happen</h5></div>
                    <div className="ttm-box-description">It’s time to us to deliver your project on time with satisfaction.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>{/* row end */}
        </div>
      </section>
      {/* portfolio-section */}
      <section style={{ backgroundImage: "url(images/Home/todomist_home-5.jpg)", backgroundSize: "cover" }} className="ttm-row bottomzero-padding-section  ttm-bg ttm-bgimage-yes bg-img5 ttm-bgcolor-skincolor clearfix">
        <div className="ttm-row-wrapper-bg-layer ttm-bg-layer" />
        <div className="container-xl pb-5 res-991-pb-0">
          <div className="row align-items-center ttm-textcolor-white">
            <div className="col-lg-5 res-991-text-center">
              <div className="section-title style3 mb-0 clearfix">
                <div className="title-header mb-0">
                  <h5>We Make Connections</h5>
                  <h2 className="title">Explore recent <span className="ttm-textcolor-white">Projects</span></h2>
                </div>
              </div>
            </div>
            <div className="col-lg-5 res-991-text-center">
              <div className="title-desc">A project is a temporary endeavor with a defined beginning and end, scope, and assigned resources.</div>
            </div>
            <div className="col-lg-2 text-right res-991-text-center res-991-mt-20">
              <a href={process.env.PUBLIC_URL + '/services'} className="ttm-btn ttm-btn-size-md ttm-btn-style-border ttm-btn-color-white"> Read More </a>
            </div>
          </div>
        </div>
      </section>
      <section className="ttm-row mt-10 zero-padding-section clearfix">
        <div className="container-fluid p-0">
          {isOpen && (
            <Lightbox
              mainSrc={images[photoIndex]}
              nextSrc={images[(photoIndex + 1) % images.length]}
              prevSrc={images[(photoIndex + images.length - 1) % images.length]}
              onCloseRequest={() => setIsOpen({ isOpen: false })}
              onMovePrevRequest={() =>
                setPhotoIndex({
                  photoIndex: (photoIndex + images.length - 1) % images.length,
                })
              }
              onMoveNextRequest={() =>
                setPhotoIndex({
                  photoIndex: (photoIndex + 1) % images.length,
                })
              }
            />
          )}
          <Slider className="row slick_slider ttm-boxes-spacing-10px" {...slick_slider} slidesToShow={5}>
            <div className="ttm-box-col-wrapper">
              <div className="featured-imagebox featured-imagebox-portfolio style1">
                {/* featured-thumbnail */}
                <div className="featured-thumbnail">
                  <img className="img-fluid" alt="" src="images/Home/Recent_project/Recent 1.jpg" />
                </div>{/* featured-thumbnail end*/}
                {/* ttm-box-view-overlay */}
                <div className="ttm-box-view-overlay">
                  <div className="featured-iconbox ttm-media-link">{/* ttm-media-link */}
                    {/* <a onClick={() => setIsOpen({ isOpen: true })} className=""><i className="ti ti-search" /></a> */}
                    <a href={process.env.PUBLIC_URL + '/web_development'} className=""><i className="ti ti-search" /></a>
                    <a href={process.env.PUBLIC_URL + '/web_development'} className="ttm_link"><i className="ti ti-link" /></a>
                  </div>
                </div>{/* ttm-box-view-overlay end*/}
                <div className="featured-content">
                  <div className="featured-title">{/* featured-title */}
                    <h5><a href={process.env.PUBLIC_URL + '/web_development'}>Web Development</a></h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="ttm-box-col-wrapper">
              {/* featured-imagebox-portfolio */}
              <div className="featured-imagebox featured-imagebox-portfolio style1">
                {/* featured-thumbnail */}
                <div className="featured-thumbnail">
                  <img className="img-fluid" alt="" src="images/Home/Recent_project/Recent 2.jpg" />
                </div>{/* featured-thumbnail end*/}
                {/* ttm-box-view-overlay */}
                <div className="ttm-box-view-overlay">
                  <div className="featured-iconbox ttm-media-link">{/* ttm-media-link */}
                    {/* <a onClick={() => setIsOpen({ isOpen: true })}><i className="ti ti-search" /></a> */}
                    <a href={process.env.PUBLIC_URL + '/it_manegement'} className=""><i className="ti ti-search" /></a>
                    <a href={process.env.PUBLIC_URL + '/it_manegement'} className="ttm_link"><i className="ti ti-link" /></a>
                  </div>
                </div>{/* ttm-box-view-overlay end*/}
                <div className="featured-content">
                  <div className="featured-title">{/* featured-title */}
                    <h5><a href={process.env.PUBLIC_URL + '/it_manegement'}>IT Management</a></h5>
                  </div>
                </div>
              </div>{/* featured-thumbnail */}
            </div>
            <div className="ttm-box-col-wrapper">
              {/* featured-imagebox-portfolio */}
              <div className="featured-imagebox featured-imagebox-portfolio style1">
                {/* featured-thumbnail */}
                <div className="featured-thumbnail">
                  <img className="img-fluid" alt="" src="images/Home/Recent_project/Recent 3.jpg" />
                </div>{/* featured-thumbnail end*/}
                {/* ttm-box-view-overlay */}
                <div className="ttm-box-view-overlay">
                  <div className="featured-iconbox ttm-media-link">{/* ttm-media-link */}
                    {/* <a onClick={() => setIsOpen({ isOpen: true })}><i className="ti ti-search" /></a> */}
                    <a href={process.env.PUBLIC_URL + '/it_consultancy'}><i className="ti ti-search" /></a>
                    <a href={process.env.PUBLIC_URL + '/it_consultancy'} className="ttm_link"><i className="ti ti-link" /></a>
                  </div>
                </div>{/* ttm-box-view-overlay end*/}
                <div className="featured-content">
                  <div className="featured-title">{/* featured-title */}
                    <h5><a href={process.env.PUBLIC_URL + '/it_consultancy'}>IT Consultancy</a></h5>
                  </div>
                </div>
              </div>{/* featured-thumbnail */}
            </div>
            <div className="ttm-box-col-wrapper">
              {/* featured-imagebox-portfolio */}
              <div className="featured-imagebox featured-imagebox-portfolio style1">
                {/* featured-thumbnail */}
                <div className="featured-thumbnail">
                  <img className="img-fluid" alt="" src="images/Home/Recent_project/Recent 4.jpg" />
                </div>{/* featured-thumbnail end*/}
                {/* ttm-box-view-overlay */}
                <div className="ttm-box-view-overlay">
                  <div className="featured-iconbox ttm-media-link">{/* ttm-media-link */}
                    {/* <a onClick={() => setIsOpen({ isOpen: true })}><i className="ti ti-search" /></a> */}
                    <a href={process.env.PUBLIC_URL + '/cloud_services'}><i className="ti ti-search" /></a>
                    <a href={process.env.PUBLIC_URL + '/cloud_services'} className="ttm_link"><i className="ti ti-link" /></a>
                  </div>
                </div>{/* ttm-box-view-overlay end*/}
                <div className="featured-content">
                  <div className="featured-title">{/* featured-title */}
                    <h5><a href={process.env.PUBLIC_URL + '/cloud_services'}>Cloud Services</a></h5>
                  </div>
                </div>
              </div>{/* featured-thumbnail */}
            </div>
            <div className="ttm-box-col-wrapper">
              {/* featured-imagebox-portfolio */}
              <div className="featured-imagebox featured-imagebox-portfolio style1">
                {/* featured-thumbnail */}
                <div className="featured-thumbnail">
                  <img className="img-fluid" alt="" src="images/Home/Recent_project/Recent 5.jpg" />
                </div>{/* featured-thumbnail end*/}
                {/* ttm-box-view-overlay */}
                <div className="ttm-box-view-overlay">
                  <div className="featured-iconbox ttm-media-link">{/* ttm-media-link */}
                    {/* <a onClick={() => setIsOpen({ isOpen: true })}><i className="ti ti-search" /></a> */}
                    <a href={process.env.PUBLIC_URL + '/graphic_design'}><i className="ti ti-search" /></a>
                    <a href={process.env.PUBLIC_URL + '/graphic_design'} className="ttm_link"><i className="ti ti-link" /></a>
                  </div>
                </div>{/* ttm-box-view-overlay end*/}
                <div className="featured-content">
                  <div className="featured-title">{/* featured-title */}
                    <h5><a href={process.env.PUBLIC_URL + '/graphic_design'}>Graphic Design</a></h5>
                  </div>
                </div>
              </div>{/* featured-thumbnail */}
            </div>

          </Slider>
        </div>
      </section>
      {/* process-section end */}
      {/* blog-section */}

      {/* blog-section end */}
    </div>
  )
}



export default Home;
